import React from "react"
import RubyStretch from "./../assets/Rubybot-stretch.gif"

export default function CreatePetIntroModal() {
  return (
    <div
      id="createPetIntroModal"
      className="modal modal-fixed-footer rf_modal "
    >
      <div className="modal-content center">
        <img
          src={RubyStretch}
          alt="Here's Ruby Stretching"
          className="rf_createImage"
        />
        <h5>Create</h5>
        <p>
          Start by selecting a breed.
          <br />
          If you don’t see yours, just select the orange Request button.
        </p>
      </div>
      <div className="modal-footer">
        <div className="modal-close waves-effect waves-green btn-flat">OK</div>
      </div>
    </div>
  )
}
