import React, { Component } from "react"
import { getAllPets } from "./../utils/rubyAPI"
import AnimatedWrapper from "./../utils/AnimatedWrapper"
import LoadingScreen from "./LoadingScreen"
import ListOfAllPets from "./ListOfAllPets"

class All extends Component {
  state = {
    pets: false,
    loading: true
  }
  componentDidMount() {
    this.props.updateHeaderType("home")
    this.props.setNewPetName(false)
    window.scrollTo(0, 0)
    getAllPets().then(pets => {
      this.setState({ pets, loading: false })
    })
  }
  render() {
    const { pets, loading } = this.state
    if (loading) return <LoadingScreen />
    return <ListOfAllPets pets={pets} />
  }
}

// export default All
export default AnimatedWrapper(All)
