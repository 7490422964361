import React, { Component } from "react"
import breeds from "./../svg-data/breeds.json"
import { obj2array, openModal } from "./utils"
import BreedPreviewSelector from "./BreedPreviewSelector"
import RequestNew from "./../assets/request-new.png"

export default class BreedSelector extends Component {
  setBreed = breed => {
    this.props.setBreed(breed)
  }
  openRequestPetModal = () => {
    openModal("requestCreationModalForm")
  }
  askForTheName = () => {
    openModal("addNewPetModal")
  }
  render() {
    const breedsList = obj2array(breeds)
    return (
      <div className="container rf_home">
        <h5>Select a breed to start</h5>

        <div className="row">
          {breedsList.map((breed, index) => (
            <BreedPreviewSelector
              breedId={index}
              breed={breed}
              key={index}
              askForTheName={this.askForTheName}
              setBreed={this.setBreed}
            />
            // <div key={index} onClick={() => this.setBreed(index)}>
            //   <img src={"/breeds/" + breed.thumbnail} />
            //   <h3>{breed.name}</h3>
            // </div>
          ))}

          <div
            className="col s6 m4 rf_createCard_noclick"
            style={{ position: "relative" }}
          >
            <div>
              <img src={RequestNew} className="rf_homeCreate" alt="add new" />
              <div className="btn-floating btn-large waves-effect waves-light orange rf_addButton ">
                <i
                  className="material-icons rf_detail_shareImage"
                  onClick={() => this.openRequestPetModal()}
                >
                  add
                </i>
              </div>
              <div className="rf_addText">
                Request a breed by clicking the orange button.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
