import React, { Component } from "react"
// import AnimatedWrapper from "../utils/AnimatedWrapper"
import RubyPreview from "./RubyPreview"
import { getUserById } from "./../utils/rubyAPI"

class PetInList extends Component {
  state = { zazzleImageLoaded: false, pet: false }
  imageHasLoaded = () => {
    this.setState({ zazzleImageLoaded: true })
  }

  componentDidMount() {
    let { pet, showInfo } = this.props
    if (showInfo) {
      getUserById(pet.userId).then(user => {
        pet.user = user
        this.setState({ pet })
      })
    } else {
      this.setState({ pet })
    }
  }

  render() {
    const { zazzleImageLoaded, pet } = this.state
    const { showInfo } = this.props
    const name = pet.petName
    const zazzleLink = `https://www.zazzle.com/api/create/at-238782375704286671?rf=238782375704286671&ax=DesignBlast&sr=250130190962296477&cg=196919359393232312&t__useQpc=false&ed=true&t__smart=false&continueUrl=https%3A%2F%2Fwww.zazzle.com%2Frubyfornia&tc=&ic=&t_name_txt=${name}&t_image_iid=${encodeURIComponent(
      pet.image
    )}`
    const zazzlePreview = `https://rlv.zazzle.com/svc/view?pid=189941738711064546&max_dim=512&at=238782375704286671&t_name_txt=${name}&t_image_url=${encodeURIComponent(
      pet.image
    )}&t_name_txt=${name}`

    if (!pet) return <></>

    return (
      <div className="col s12 m6 petInListWrapper">
        <div className="rf_createCard" style={{ position: "relative" }}>
          <a href={zazzleLink}>
            <img
              src={zazzlePreview}
              alt={name}
              height="512"
              width="512"
              className="zazzlePetCardImg"
              onLoad={() => this.imageHasLoaded()}
            />
          </a>
          <RubyPreview
            pet={pet}
            editLink={"edit/" + pet.id}
            name={name}
            imageScr={pet.image}
            zazzleImageLoaded={zazzleImageLoaded}
          />
        </div>
        {zazzleImageLoaded && <div className="rf_usersDogName">{name}</div>}
        {showInfo && (
          <div>
            {pet.user && (
              <div>
                <img
                  className="profilePic"
                  src={pet.user.photoURL + "?height=45"}
                />
                <h5>by {pet.user.displayName}</h5>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
export default PetInList
// export default AnimatedWrapper(PetInList)
