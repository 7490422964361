import firebase from "firebase"
var config = {
  apiKey: "AIzaSyADspXFfyIlO6MfdKF27rV2A8kVRQ2AvYQ",
  authDomain: "rubyfornia-2018.firebaseapp.com",
  databaseURL: "https://rubyfornia-2018.firebaseio.com",
  projectId: "rubyfornia-2018",
  storageBucket: "rubyfornia-2018.appspot.com",
  messagingSenderId: "111923206427"
}
firebase.initializeApp(config)
export const auth = firebase.auth
export const provider = new firebase.auth.FacebookAuthProvider()
export default firebase
