import React, { Component } from "react"
import Hammer from "hammerjs"
export default class Sticker extends Component {
  state = {
    message: "",
    transform: "",
    posX: Math.random() * 280,
    posY: Math.random() * 200,
    scale: 1,
    lastPosX: 0,
    lastPosY: 0,
    maxPosX: 0,
    maxPosY: 0,
    lastScale: 1,
    lastRotation: 0,
    rotation: 0,
    rotateStart: 0,
    elementWidth: 0,
    panStartX: 0,
    panStartY: 0,
    flipped: 0
  }

  transform() {
    const { flipped, scale, rotation } = this.state
    let transform = ""
    transform = "scale(" + scale + ") rotate(" + rotation + "deg)"
    if (flipped) transform += " scaleX(-1)"
    this.setState({ transform: transform })
  }

  onPan(e) {
    this.setState({ message: e.deltaX })
    const { lastPosX, lastPosY } = this.state

    this.setState({ posX: lastPosX + e.deltaX })
    this.setState({ posY: lastPosY + e.deltaY })

    this.transform()
  }
  onPanStart(e) {
    this.setState({ panStartX: e.posX, panStartY: e.posY })
  }
  onPanEnd(e) {
    const { posX, posY } = this.state
    this.setState({ lastPosX: posX, lastPosY: posY })
  }

  handleTap() {
    this.setState({ message: "HANDLE TAP!" })
  }
  handleDoubleTap() {
    const { flipped } = this.state
    if (flipped) this.setState({ flipped: 0 })
    else this.setState({ flipped: 1 })
    this.setState({ message: "HANDLE DOUBLETAP!" })
    this.transform()
  }
  handleClick() {
    this.setState({ scale: 2 }, this.transform())
    this.handleTap()
  }
  handleSwipe() {
    this.setState({ message: "HANDLE SWIPE!" })
  }
  onPinchEnd(e) {
    const { scale } = this.state
    this.setState({ message: scale })
    this.setState({ lastScale: scale })
  }
  onPinchStart(e) {
    this.setState({ message: "PINCHSTART!" })
  }
  onRotateStart(e) {
    this.setState({ rotateStart: Math.round(e.rotation) })
  }
  onRotateEnd(e) {
    this.setState({ lastRotation: this.state.rotation })
  }
  onRotate(e) {
    const { rotateStart, lastRotation } = this.state
    this.setState({ message: "rotation: " + e.rotation })
    const diff = rotateStart - Math.round(e.rotation)
    this.setState({ rotation: lastRotation - diff })
  }
  onPinch(e) {
    const { lastScale, lastPosX, lastPosY } = this.state
    const scale = Math.max(0.2, Math.min(lastScale * e.scale, 4))

    this.setState({ posX: lastPosX + e.deltaX })
    this.setState({ posY: lastPosY + e.deltaY })

    this.setState({ scale })
    this.transform()
  }

  componentDidMount() {
    this.hammer = Hammer(this._sticker)
    this.hammer.get("pinch").set({ enable: true })
    this.hammer.get("rotate").set({ enable: true })
    // this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL })
    this.hammer.on("pan", e => this.onPan(e))
    this.hammer.on("panstart", e => this.onPanStart(e))
    this.hammer.on("panend", e => this.onPanEnd(e))
    // this.hammer.on("swipeleft", this.next) // remove ()
    // this.hammer.on("swiperight", this.prev) // remove ()

    this.hammer.on("rotatestart", e => this.onRotateStart(e))
    this.hammer.on("rotateend", e => this.onRotateEnd(e))
    this.hammer.on("rotate", e => this.onRotate(e))
    this.hammer.on("tap", e => this.handleTap(e))
    this.hammer.on("doubletap", e => this.handleDoubleTap(e))
    this.hammer.on("click", e => this.handleClick(e))
    this.hammer.on("pinch", e => this.onPinch(e))
    this.hammer.on("pinchstart", e => this.onPinchStart(e))
    this.hammer.on("pinchend", e => this.onPinchEnd(e))

    const { posX, posY } = this.state

    this.transform()
    this.setState({ lastPosX: posX, lastPosY: posY })
  }

  render() {
    const {
      message,
      transform,
      scale,
      rotation,
      rotateStart,
      posY,
      posX,
      flipped
    } = this.state
    return (
      <React.Fragment>
        <div
          ref={el => (this._sticker = el)}
          style={{ display: "inline-block" }}
        >
          <div
            className="stickerFixed"
            style={{ WebkitTransform: transform, top: posY, left: posX }}
          >
            {this.props.children}
          </div>
        </div>
        {false && (
          <div>
            <div>Msg: {message}</div>
            <div>Flipped: {flipped}</div>
            <div>Scale: {scale}</div>
            <div>Rotation: {rotation}</div>
            <div>RotationStart: {rotateStart}</div>
            <div>Transform: {transform}</div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
