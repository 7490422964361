import React, { Component } from "react"
import LoadingScreen from "./LoadingScreen"
import { getPetById, deletePetById } from "./../utils/rubyAPI"
import AnimatedWrapper from "../utils/AnimatedWrapper"
import PetDetailBody from "./PetDetailBody"
import { Redirect } from "react-router"
import EditPetDetailModal from "./EditPetDetailModal"
import DownloadPetModal from "./DownloadPetModal"
import SharePetModal from "./SharePetModal"
import ImageCopiedModal from "./ImageCopiedModal"

class PetDetail extends Component {
  state = {
    loading: true,
    pet: false,
    petId: false,
    redirectHome: false
  }
  deletePet = () => {
    this.setState({ loading: true })
    const { petId } = this.props
    deletePetById(petId).then(() => {
      this.setState({ loading: false })
      this.setState({ redirectHome: true })
    })
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    getPetById(this.props.petId).then(pet => {
      this.setState({ pet, loading: false, petId: this.props.petId })
    })
  }
  componentDidMount() {
    this.props.updateHeaderType("detail")
  }
  render() {
    const { loading, pet, petId, redirectHome } = this.state

    if (redirectHome) return <Redirect to="/" />
    if (loading) return <LoadingScreen />
    return (
      <>
        <PetDetailBody
          className="petDetailBody"
          deletePet={this.deletePet}
          petId={petId}
          pet={pet}
        />
        <EditPetDetailModal pet={pet} />
        <DownloadPetModal />
        <SharePetModal />
        <ImageCopiedModal />
      </>
    )
  }
}

// export default PetDetail
export default AnimatedWrapper(PetDetail)
