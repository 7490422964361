import React, { Component } from "react"
import RubySVGPreview from "./RubySVGPreview"
class RubyPreview extends Component {
  state = {
    zazzleImageLoaded: false
  }
  componentDidUpdate() {
    if (!this.state.zazzleImageLoaded)
      if (this.props.zazzleImageLoaded)
        this.setState({ zazzleImageLoaded: true })
  }
  render() {
    let imgClass = "rubyPreviewSVG hidden"
    if (this.state.zazzleImageLoaded) {
      imgClass = "rubyPreviewSVG"
    }
    const { editLink, pet } = this.props
    return <RubySVGPreview pet={pet} editLink={editLink} imgClass={imgClass} />
  }
}
export default RubyPreview
