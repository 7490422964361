import React, { Component } from "react"
import Pet from "./Pet"
import AnimatedWrapper from "./../utils/AnimatedWrapper"
import breeds from "./../svg-data/breeds.json"
import { obj2array } from "./utils"
import { getPetById } from "./../utils/rubyAPI"
// import M from "materialize-css/dist/js/materialize.min.js"
class NewPet extends Component {
  state = {
    pet: false,
    showHeader: true
  }

  componentDidMount = () => {
    this.props.updateHeaderType("new")
    const breedsList = obj2array(breeds)
    breedsList.forEach(breed => {
      const img = new Image()
      img.src = breed.thumbnail
    })
    if (this.props.petId) {
      getPetById(this.props.petId).then(pet => {
        pet.id = this.props.petId
        // console.log({ pet })
        // this.setState({ pet, loading: false, petId: this.props.petId })
        this.setState({ pet })
      })
    }
    // var elems = document.querySelectorAll("#createPetIntroModal")
    // var instances = M.Modal.init(elems, { preventScrolling: false })
    // instances[0].open()
  }

  hideHeader = () => {
    if (this.state.showHeader) {
      this.setState({ showHeader: false })
      this.props.updateHeaderType("none")
    }
  }

  render() {
    const { pet } = this.state
    const { history, userId, name, loading } = this.props
    return (
      <>
        <div className="petWrapper">
          <Pet
            history={history}
            pet={pet}
            userId={userId}
            name={name}
            loading={loading}
            hideHeader={this.hideHeader}
          />
        </div>
      </>
    )
  }
}

// export default NewPet
export default AnimatedWrapper(NewPet)
