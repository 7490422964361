import React, { Component } from "react"
import AnimatedWrapper from "../utils/AnimatedWrapper"
import Reward from "react-rewards"
import { uploadeImageAndGetZazzleURL } from "./utils"
import { Link } from "react-router-dom"
import { openModal } from "./utils"

class PetDetailBody extends Component {
  state = {
    zazzleLink: false,
    defaultDiscoverMerchButtonText: "Discover Merch!!!"
  }
  selectText = element => {
    var range = false
    if (document.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(element)
      range.select()
    } else if (window.getSelection) {
      var selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(element)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  componentDidMount() {
    // openModal("editPetDetailModal")
  }

  generateZazzleLink = async pet => {
    this.reward.rewardMe()
    this.setState({ defaultDiscoverMerchButtonText: "loading..." })
    const zazzleLink = await uploadeImageAndGetZazzleURL(pet.image, pet.petName)
    this.setState({ zazzleLink })
  }

  render() {
    const { zazzleLink, defaultDiscoverMerchButtonText } = this.state

    let showMerchButton = defaultDiscoverMerchButtonText
    if (zazzleLink) {
      showMerchButton = (
        <a
          style={{ color: "white" }}
          href={zazzleLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Take me there Right Now!!
        </a>
      )
    }

    const { pet, deletePet } = this.props
    return (
      <div className="petDetailBody container center-align">
        <h2 className="petDetailName center-align">This is {pet.petName}</h2>
        <div
          ref={img => (this._img = img)}
          className="copyable center-align"
          style={{ marginBottom: "30px" }}
        >
          <img id="petImg" alt={pet.name} src={pet.image} />
        </div>

        <Reward
          ref={ref => {
            this.reward = ref
          }}
          type="confetti"
        >
          <div
            className="btn"
            style={{ cursor: "pointer", marginBottom: "40px" }}
            onClick={() => this.generateZazzleLink(pet)}
          >
            {showMerchButton}
          </div>
        </Reward>
        <div className="row">
          {/* <div className="col s1" /> */}
          <div className="col s2 center">
            <Link to={"/edit/" + this.props.petId}>
              <div className="rf_detail_edit">
                <i className="material-icons">edit</i>
              </div>
            </Link>
          </div>
          <div className="col s2 center">
            <div
              className="rf_detail_edit"
              onClick={() => openModal("editPetDetailModal")}
            >
              <i className="material-icons">edit</i>
            </div>
          </div>
          <div className="col s2 center">
            <div
              className="rf_detail_copyImage"
              onClick={() => openModal("imageCopiedModal")}
            >
              <i className="material-icons">file_copy</i>
            </div>
          </div>
          <div className="col s2 center">
            <div
              className="rf_detail_downloadImage"
              onClick={() => openModal("downloadPetModal")}
            >
              <i className="material-icons">cloud_download</i>
            </div>
          </div>
          <div className="col s2 center">
            <div
              className="rf_detail_shareImage"
              onClick={() => openModal("sharePetModal")}
            >
              <i className="material-icons">share</i>
            </div>
          </div>
          <div className="col s2 center">
            <div className="rf_detail_deleteImage" onClick={deletePet}>
              <i className="material-icons red-text">delete</i>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnimatedWrapper(PetDetailBody)
