import React from "react"
import WelcomeGif from "./../assets/welcome-all-dogs.gif"

export default function WelcomeToRubyforniaModal() {
  return (
    <div
      id="welcomeToRubyforniaModal"
      className="modal modal-fixed-footer rf_modal rf_w2rmodal"
    >
      <div className="modal-content rf_w2rmodal">
        <img
          src={WelcomeGif}
          className="responsive-img"
          alt="welcome to rubyfornia!"
        />
        <h4>Welcome</h4>
        <p>
          Your home page shows your personal pack. Create your first design by
          clicking the red plus button.
        </p>
        <h5 className="rf_w2r">Welcome to Rubyfornia!</h5>
      </div>
      <div className="modal-footer">
        <div
          className="modal-close waves-effect waves-green btn-flat"
          id="click2share"
        >
          OK
        </div>
      </div>
    </div>
  )
}
