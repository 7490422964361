import React, { Component } from "react"
import M from "materialize-css/dist/js/materialize.min.js"
import { Link } from "react-router-dom"
import { HeaderContext } from "./HeaderContext"
import { getPetsByUserId, deletePetById } from "./../utils/rubyAPI"
export default class Header extends Component {
  state = {
    headerClass: "light-blue lighten-1"
  }
  componentDidMount() {
    var elem = document.querySelector(".sidenav")
    M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250
    })
    if (this.props.type && this.props.type === "new") {
      setTimeout(() => {
        this.setState({ headerClass: "light-green lighten-1" })
      }, 50)
    }
    if (this.props.type && this.props.type === "detail") {
      setTimeout(() => {
        this.setState({ headerClass: "amber darken-1" })
      }, 50)
    }
  }

  deleteAllDogs = async () => {
    const dogs = await getPetsByUserId(this.props.user.uid)
    dogs.forEach(dog => {
      deletePetById(dog.id)
    })
  }

  closeSideNavAndLogOut = () => {
    this.closeSideNav()
    this.props.logout()
  }

  closeSideNav = () => {
    var elem = document.querySelector(".sidenav")
    var instance = M.Sidenav.getInstance(elem)
    instance.close()
  }

  render() {
    const { user } = this.props

    return (
      <HeaderContext.Consumer>
        {({ headerType }) => {
          let pageHeaderTitle = "Home"
          let willShowBackOption = false
          let headerClass = "light-blue lighten-1"
          if (headerType === "new") headerClass = "light-green lighten-1"
          if (headerType === "detail") headerClass = "amber darken-1"
          if (headerType === "new") {
            pageHeaderTitle = "Create"
            willShowBackOption = true
          }
          if (headerType === "detail") {
            pageHeaderTitle = "Detail"
            willShowBackOption = true
          }
          let headerWrapperClass = "headerWrapper"
          if (headerType === "none") {
            headerWrapperClass = "headerWrapper hidden"
          }

          return (
            <div className={headerWrapperClass}>
              <nav className={headerClass} role="navigation">
                <div className="nav-wrapper container">
                  <ul id="nav-mobile" className="sidenav">
                    <li>
                      <div className="user-view">
                        <div className="background">
                          <img
                            src="http://haroldl.com/rf/images/rf_navbg.jpg"
                            alt="userImage"
                          />
                        </div>
                        <a href="#user">
                          <img
                            className="circle"
                            alt="user profile pic"
                            src={user.photoURL}
                          />
                        </a>
                        <a href="#name">
                          <span className="rf_name txt2">
                            {user.displayName}
                          </span>
                        </a>
                        <a href="#email">
                          <span className="rf_email txt2">{user.email}</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <Link to="/" onClick={this.closeSideNav}>
                        <i className="material-icons">home</i>
                        Home
                      </Link>
                    </li>
                    {/* <li>
                      <a onClick={this.deleteAllDogs} href="#">
                        <i className="material-icons">history</i>
                        Delete all dogs
                      </a>
                    </li>
                    */}
                    <li>
                      <div
                        onClick={() => this.closeSideNavAndLogOut()}
                        href="#"
                      >
                        <i className="material-icons">logout</i>
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>

              {!willShowBackOption && (
                <>
                  <div
                    data-target="nav-mobile"
                    className="sidenav-trigger rf_menu"
                  >
                    <i className="material-icons">menu</i>
                  </div>
                  <Link to="/">
                    <div className="rf_pageName">{pageHeaderTitle}</div>
                  </Link>
                </>
              )}
              {willShowBackOption && (
                <>
                  <Link to="/" onClick={this.closeSideNav} className="rf_menu">
                    <i className="material-icons">arrow_back</i>
                  </Link>
                  <div className="rf_pageName">{pageHeaderTitle}</div>
                </>
              )}
            </div>
          )
        }}
      </HeaderContext.Consumer>
    )
  }
}
