import React from "react"

export default function DesignerIntroModal() {
  return (
    <div id="modal1firstVisit" className="modal modal-fixed-footer rf_modal">
      <div className="modal-content center">
        <h4>Designer</h4>
        <p>
          Tap or click to toggle options. <br />
          Click SAVE when you see your dog.
        </p>
        <h1>
          <span
            role="img"
            aria-labelledby="Emoji of a Dog"
            arial-label="Emoji of a Dog"
          >
            🐶
          </span>
        </h1>
      </div>
      <div className="modal-footer">
        <div className="modal-close waves-effect waves-green btn-flat">OK</div>
      </div>
    </div>
  )
}
