import React, { Component } from "react"
// import screen1 from "./../assets/screens/screen1.png"
// import screen2 from "./../assets/screens/screen2.png"
// import screen3 from "./../assets/screens/screen3.png"
// import screen4 from "./../assets/screens/screen4.png"
// import screen5 from "./../assets/screens/screen5.png"

export default class Screens extends Component {
  render() {
    return (
      <div>
        {/*
        <img src={screen1} />
        <img src={screen2} />
        <img src={screen3} />
        <img src={screen4} />
        <img src={screen5} />
    */}
      </div>
    )
  }
}
