import React, { Component } from "react"
import LoadingScreen from "./LoadingScreen"
import BreedSelector from "./BreedSelector"
import breeds from "./../svg-data/breeds.json"
import AnimatedWrapper from "./../utils/AnimatedWrapper"
import { withRouter } from "react-router-dom"
import PetEditor from "./PetEditor"
import { obj2array } from "./utils"

class Pet extends Component {
  state = {
    loading: true,
    bodyParts: null,
    selectedParts: false,
    selectedColors: false,
    activePartId: false,
    breed: false,
    breedId: false,
    controllerBodyPart: 0,
    history: [],
    historyPointer: false,
    redoList: [],
    name: false,
    petId: false,
    isBlinking: false,
    canBlink: true
  }

  updateComponent = async () => {
    // ? :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // ? Here's how I'm loading the pet when you're trying to edit an existing one!
    // ? :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    if (this.props.pet) {
      if (!this.state.name) {
        this.setState({ loading: true })
        const pet = this.props.pet
        const { breedId, selectedParts, id, selectedColors, petName } = pet
        this.setState({ name: petName, petId: id })

        await this.loadBreed(breedId, selectedParts, selectedColors)
        setTimeout(() => {
          this.setState({
            loading: false
          })
        }, 500)
      }
    }

    if (this.props.name) {
      if (!this.state.name) {
        this.setState({ name: this.props.name, loading: false })
      }
    }
  }

  componentDidUpdate = () => {
    this.updateComponent()
  }

  componentDidMount = () => {
    if (!this.props.pet) {
      if (!this.props.loading) this.setState({ loading: false })
    }

    // ! remove later!
    // ! :::: These 2 lines will take you right to the designer!!!
    // this.setState({ name: "Javi" })
    // this.loadBreed(1)
  }

  loadBreed = async (
    breedId,
    selectedParts = false,
    selectedColors = false
  ) => {
    this.setState({
      breed: breeds[breedId],
      breedId: breedId,
      selectedColors: false,
      selectedParts: false,
      activePartId: false
    })

    if (breeds[breedId]) {
      const filePath = breeds[breedId].path
      // ./../ svg - data / Asset10.svg.json
      import("./../svg-data/" + filePath + ".json").then(module => {
        const moduleArray = obj2array(module)
        this.setState({ bodyParts: moduleArray })
        if (!selectedParts) {
          selectedParts = {}
          moduleArray.forEach(part => {
            selectedParts[part.id] = 0
          })
        }
        this.setState({ selectedParts: selectedParts })
        if (!selectedColors) {
          selectedColors = []
          moduleArray.forEach(part => {
            selectedColors[part.id] = "#FFFFFF"
          })
        }

        this.setState({ selectedColors: selectedColors })
      })
    }
    return true
  }

  resetState = () => {
    this.setState({
      selectedColors: false,
      selectedParts: false,
      breed: false,
      activePartId: false
    })
  }

  setLoading = bool => {
    this.setState({ loading: bool })
  }

  render() {
    // console.log("arrived at Render Pet 😇")
    // console.log(this.props)
    // console.log(this.state)

    const {
      loading,
      selectedParts,
      selectedColors,
      name,
      breed,
      bodyParts,
      breedId,
      petId,
      isBlinking
    } = this.state
    const { hideHeader, userId } = this.props
    if (loading) {
      return <LoadingScreen />
    }

    if (!name) {
      return <BreedSelector setBreed={this.loadBreed} />
    }

    if (!selectedParts) {
      return <LoadingScreen />
    }

    return (
      <PetEditor
        bodyParts={bodyParts}
        selectedParts={selectedParts}
        selectedColors={selectedColors}
        hideHeader={hideHeader}
        name={name}
        breed={breed}
        userId={userId}
        history={this.props.history}
        breedId={breedId}
        petId={petId}
        isBlinking={isBlinking}
        setLoading={this.setLoading}
      />
    )
  }
}

export default withRouter(AnimatedWrapper(Pet))
