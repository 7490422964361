import React, { Component } from "react"
import {
  colors,
  getRandom,
  openModal,
  closeModal,
  detectBrowser,
  sleep
} from "./utils"
import Group from "./Group"
import GroupOfPaths from "./GroupOfPaths"
import { addPet, updatePetById } from "./../utils/rubyAPI"
import soundEffect from "./../assets/swoosh.mp3"
import rightArrow from "./../assets/rightArrow.png"
import leftArrow from "./../assets/leftArrow.png"
import M from "materialize-css/dist/js/materialize.min.js"
import camelify from "camelify"
import Sticker from "./Sticker"
import InputColor from "react-input-color"

export default class PetEditor extends Component {
  state = {
    loading: true,
    bodyParts: null,
    selectedParts: false,
    selectedColors: false,
    activePartId: false,
    breed: false,
    breedId: false,
    controllerBodyPart: 0,
    history: [],
    historyPointer: false,
    redoList: [],
    name: false,
    petId: false,
    isBlinking: false,
    canBlink: true,
    isSavingPet: false,
    stickerOptions: [],
    stickersUsed: [],
    color: "#5e72e4"
  }

  componentDidMount() {
    this.props.hideHeader()
    const stickerOptions = [
      "https://img.icons8.com/color/384/corgi.png",
      "https://img.icons8.com/color/384/cat-footprint.png",
      "https://img.icons8.com/plasticine/384/dog.png"
    ]
    this.setState({ stickerOptions })
    // Modal when you visit the designer screen: Disabled for now!
    // openModal("modal1firstVisit")

    this.interval = setInterval(() => {
      if (this.state.canBlink) {
        const random = Math.floor(Math.random() * 8) + 1
        if (random > 7) {
          this.setState({ isBlinking: true })
        } else this.setState({ isBlinking: false })
      } else {
        this.setState({ isBlinking: false })
      }
    }, 150)
  }

  componentWillMount() {
    // this was connected with the fucntion right after this one: "handleKeyDown(event)"
    //
    // document.addEventListener("keydown", this.handleKeyDown.bind(this))

    this.setState({
      petId: this.props.petId,
      name: this.props.name,
      bodyParts: this.props.bodyParts,
      selectedParts: this.props.selectedParts,
      selectedColors: this.props.selectedColors,
      breedId: this.props.breedId,
      browser: detectBrowser()
    })
  }

  // handleKeyDown(event) {
  //   if (event.key === "ArrowLeft") {
  //     this.arrowClickedGoToPreviousColorOption()
  //   }
  //   if (event.key === "ArrowRight") {
  //     this.arrowClickedGoToNextColorOption()
  //   }
  // }

  showDiscardModal = () => {
    openModal("discardModal")
  }

  discard = () => {
    closeModal("discardModal")
    this.props.history.push("/")
  }

  saveCurrentState = state => {
    const { selectedColors, selectedParts, history } = state
    const newEvent = {
      selectedColors,
      selectedParts
    }
    history.push(newEvent)
    this.setState({ history, historyPointer: history.length, redoList: [] })
  }

  undoHistory = () => {
    const currentState = {
      selectedColors: this.state.selectedColors,
      selectedParts: this.state.selectedParts
    }
    var lastItem = this.state.history.pop()
    const { selectedColors, selectedParts } = lastItem
    this.setState({
      selectedColors,
      selectedParts
    })
    this.setState(prevState => ({
      redoList: [...prevState.redoList, currentState]
    }))
    this.playWhoosh()
  }

  playWhoosh = () => {
    const audioEffect = new Audio(soundEffect)
    audioEffect.play()
  }

  redoHistory = () => {
    const currentState = {
      selectedColors: this.state.selectedColors,
      selectedParts: this.state.selectedParts
    }
    this.state.history.push(currentState)

    this.playWhoosh()

    var lastItem = this.state.redoList.pop()
    const { selectedColors, selectedParts } = lastItem
    this.setState({
      selectedColors,
      selectedParts
    })

    var newRedoList = [...this.state.redoList]
    newRedoList.slice(-1)
    this.setState({ redoList: newRedoList })
  }

  randomPet = () => {
    let newSelectedParts = {}
    // let newSelectedColors = {}
    const { bodyParts } = this.state
    bodyParts.forEach(part => {
      newSelectedParts[part.id] = getRandom(0, part.partCount)
      // newSelectedColors[part.id] = getRandom(0, colors.length)
    })
    this.setState({
      selectedParts: newSelectedParts
      // selectedColors: newSelectedColors
    })
  }

  arrowClickedGoToNextBodyOption = () => {
    this.goToNextBodyOption(
      this.state.bodyParts[this.state.controllerBodyPart].id
    )
  }

  arrowClickedGoToPreviousBodyOption = () => {
    this.goToPreviousBodyOption(
      this.state.bodyParts[this.state.controllerBodyPart].id
    )
  }

  goToNextBodyPart = () => {
    let nextBodyPartValue = this.state.controllerBodyPart + 1
    if (this.state.bodyParts.length === nextBodyPartValue) nextBodyPartValue = 0
    this.setState({
      controllerBodyPart: nextBodyPartValue
    })
    this.selectBodyPart(this.state.bodyParts[this.state.controllerBodyPart].id)
  }

  goToPreviousBodyPart = () => {
    let nextBodyPartValue
    if (this.state.controllerBodyPart === 0) {
      nextBodyPartValue = this.state.bodyParts.length - 1
    } else {
      nextBodyPartValue = this.state.controllerBodyPart - 1
    }

    this.setState({
      controllerBodyPart: nextBodyPartValue
    })
    this.selectBodyPart(this.state.bodyParts[this.state.controllerBodyPart].id)
  }

  handleClick = (partId, index) => {
    this.selectBodyPart(partId)
    this.setState({ controllerBodyPart: index })
    this.goToNextBodyOption(partId)
  }

  getBodyPartById = partId => {
    const { bodyParts } = this.state
    return bodyParts.filter(part => part.id === partId)[0]
  }

  goToNextBodyOption = partId => {
    this.saveCurrentState(this.state)
    const bodyPart = this.getBodyPartById(partId)

    const { selectedParts } = this.state
    if (selectedParts[partId] + 1 < bodyPart.partCount) {
      this.setState({
        selectedParts: {
          ...this.state.selectedParts,
          [partId]: selectedParts[partId] + 1
        }
      })
    } else {
      this.setState({
        selectedParts: {
          ...this.state.selectedParts,
          [partId]: 0
        }
      })
    }

    this.playWhoosh()
  }

  goToPreviousBodyOption = partId => {
    this.saveCurrentState(this.state)
    const bodyPart = this.getBodyPartById(partId)

    const { selectedParts } = this.state
    if (selectedParts[partId] === 0) {
      this.setState({
        selectedParts: {
          ...this.state.selectedParts,
          [partId]: bodyPart.partCount - 1
        }
      })
    } else {
      this.setState({
        selectedParts: {
          ...this.state.selectedParts,
          [partId]: selectedParts[partId] - 1
        }
      })
    }

    this.playWhoosh()
  }

  selectBodyPart = partId => {
    this.setState({ activePartId: partId })
  }

  splice = function(string, idx, rem, str) {
    return string.slice(0, idx) + str + string.slice(idx + Math.abs(rem))
  }

  triggerSavePet = async () => {
    const { isSavingPet } = this.state
    if (isSavingPet) {
      M.toast({ html: "🐕 Your Dog is being saved...", displayLength: 1500 })
    } else {
      this.setState({ isSavingPet: true, canBlink: false, isBlinking: false })
      await sleep(150)
      this.savePet()
    }
  }

  savePet = () => {
    // console.log("SAVE CLICKED!!! 📡")
    // thisComponent.setState({ loading: true })
    // thisComponent.props.setLoading(true)

    const { breedId, selectedColors, selectedParts, name } = this.state
    const { userId } = this.props
    var styleStr = ""
    var defs = document.createElementNS("http://www.w3.org/2000/svg", "defs")
    var style = document.createElementNS("http://www.w3.org/2000/svg", "style")
    style.innerHTML = styleStr
    defs.appendChild(style)
    if (this._svg !== null) {
      console.log("OK CASE 1")
      var svgHtml = this._svg.innerHTML
      // svgHtml = this.splice(
      //   svgHtml,
      //   svgHtml.indexOf(">") + 1,
      //   0,
      //   defs.innerHTML
      // )
      console.log({ svgHtml })
      const canvas = this._canvas
      const ctx = canvas.getContext("2d")
      const data = encodeURIComponent(svgHtml)
      var img = new Image()
      const thisComponent = this
      img.onload = async function() {
        ctx.drawImage(img, 0, 0)
        const imgBase64 = canvas.toDataURL()
        const formData = new FormData()
        formData.append("file", imgBase64)
        formData.append("upload_preset", "rubyfornia")
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/javierezpeleta/image/upload",
          {
            method: "POST",
            body: formData
          }
        )

        const file = await res.json()
        const imageURL = file.secure_url
        const dataToBeSaved = {
          petName: name,
          userId: userId,
          breedId: breedId,
          selectedColors: selectedColors,
          selectedParts: selectedParts,
          image: imageURL,
          svg: svgHtml
        }
        if (thisComponent.state.petId) {
          dataToBeSaved.lastUpdated = Date.now()
          const petId = thisComponent.state.petId
          updatePetById(petId, dataToBeSaved).then(() => {
            openModal("savePetSuccessModal")
            // thisComponent.props.history.push("/")
          })
        } else {
          dataToBeSaved.created = Date.now()
          dataToBeSaved.lastUpdated = Date.now()
          addPet(dataToBeSaved).then(() => {
            openModal("savePetSuccessModal")
            // thisComponent.props.history.push("/")
          })
        }

        thisComponent.setState({ isSavingPet: false })

        return true
      }
      img.src = "data:image/svg+xml," + data
      // var image = canvas.toDataURL("image/png")
      // window.location.href = image
    } else {
      console.log("OK CASE 2")
    }
    this.setState({ loading: false })
  }

  renderStickers() {
    const { stickersUsed } = this.state
    let stickers = []
    for (let i = 0; i < stickersUsed.length; i++) {
      stickers.push(
        <Sticker>
          <img src={stickersUsed[i]} className="App-logo" alt="logo" />
        </Sticker>
      )
    }
    return stickers
  }

  addSticker(e) {
    const imgSrc = e.target.src

    this.setState(state => ({
      stickersUsed: [...state.stickersUsed, imgSrc]
    }))

    return false
    const { stickerCount } = this.state
    this.setState({ stickerCount: stickerCount + 1 })
    console.log({ stickerCount })
  }

  setColor(color, partId) {
    const { selectedColors } = this.state
    console.log({ selectedColors })
    console.log({ color })
    console.log({ partId })
    console.log("🎨 COLOR HERE!!" + color.hex)

    selectedColors[partId] = color.hex
    this.setState({ selectedColors: selectedColors })
  }

  render() {
    const {
      history,
      redoList,
      isBlinking,
      selectedColors,
      activePartId,
      selectedParts,
      controllerBodyPart,
      bodyParts,
      browser,
      isSavingPet,
      stickerOptions,
      color
    } = this.state

    const { name, breed } = this.props
    const viewBoxParts = breed.viewBox.split(" ")
    const canvasWidth = viewBoxParts[2]
    const canvasHeight = viewBoxParts[3]

    let canUndo = "disabled"
    let canRedo = "disabled"
    if (history.length > 0) canUndo = ""
    if (redoList.length > 0) canRedo = ""

    const bodyPartVariation =
      selectedParts[bodyParts[controllerBodyPart].id] + 1
    const totalNumberOfVariations = bodyParts[controllerBodyPart].partCount
    const controllerBodyPartActive = bodyParts[controllerBodyPart].id

    // const colorVariation = selectedColors[bodyParts[controllerBodyPart].id] + 1
    // const totalNumberOfColorVariations = colors.length

    let saveButtonTxt = "Save"
    if (isSavingPet) {
      saveButtonTxt = "Saving..."
    }
    return (
      <>
        <div className="rf_interface">
          <div className="rf_designer valign-wrapper">
            <div className="rf_dogname noselect">
              <div
                className={`${canUndo} rf_designer_undo btn btn-flat left`}
                onClick={this.undoHistory}
              >
                <i className="material-icons">undo</i>
              </div>
              {name}
              <div
                className={`${canRedo} rf_designer_redo right btn btn-flat`}
                onClick={this.redoHistory}
              >
                <i className="material-icons">redo</i>
              </div>
            </div>

            <div className="rf_design">
              {this.renderStickers()}
              <div
                ref={r => {
                  this._svg = r
                }}
              >
                <svg
                  className="petSVG"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={breed.viewBox}
                >
                  {this.state.bodyParts.map((part, index) => {
                    if (!isSavingPet) {
                      if (part.id === "Eyes") {
                        // console.log(part.id + " .... " + isBlinking)
                        if (isBlinking) return <React.Fragment key={index} />
                      }
                      if (part.id === "Eyes_Closed") {
                        if (!isBlinking) {
                          return <React.Fragment key={index} />
                        }
                      }
                    }

                    var pathColorStyle = colors[0]
                    if (selectedColors[part.id]) {
                      // pathColorStyle = colors[selectedColors[part.id]]
                      pathColorStyle = selectedColors[part.id]
                    }
                    var pathClass = part.class
                    if (part.id === activePartId) pathClass += " active"
                    return renderSvgPart(
                      part,
                      pathClass,
                      pathColorStyle,
                      selectedParts[part.id],
                      this.handleClick,
                      index
                    )
                  })}
                </svg>
              </div>
            </div>

            <div className="rf_designer_discard">
              <div
                className="btn btn-flat waves-effect waves-blue modal-trigger"
                onClick={() => this.showDiscardModal()}
              >
                Discard
              </div>
            </div>

            <div className="stickerSelectionGroup">
              {stickerOptions.map((sticker, index) => {
                return (
                  <img
                    key={index}
                    onClick={e => this.addSticker(e)}
                    src={sticker}
                  />
                )
              })}
            </div>

            <div className="rf_designer_random">
              <div
                onClick={this.randomPet}
                className="btn btn-flat waves-effect waves-blue"
              >
                Random
              </div>
            </div>
            <div className="rf_designer_save">
              <div
                onClick={this.triggerSavePet}
                className="btn btn-flat waves-effect waves-blue"
              >
                {saveButtonTxt}
              </div>
            </div>
          </div>

          <div className="valign-wrapper">
            <div className="rf_designer_controls">
              <div className="row">
                <div
                  className="col s1 offset-s1 rf_toggle noselect"
                  onClick={() => this.goToPreviousBodyPart()}
                >
                  <img
                    src={leftArrow}
                    className="rf_designer_arrow"
                    alt="previous body part"
                  />
                </div>
                <div
                  className="col s8 rf_toggle noselect"
                  onClick={() => this.goToNextBodyPart()}
                >
                  {controllerBodyPartActive}
                </div>
                <div
                  className="col s1 rf_toggle noselect"
                  onClick={() => this.goToNextBodyPart()}
                >
                  <img
                    src={rightArrow}
                    className="rf_designer_arrow"
                    alt="next body part"
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="col s1 offset-s1 rf_toggle noselect"
                  onClick={() => this.arrowClickedGoToPreviousBodyOption()}
                >
                  <img
                    src={leftArrow}
                    className="rf_designer_arrow"
                    alt="previous body option"
                  />
                </div>
                <div
                  className="col s8 rf_toggle noselect"
                  onClick={() => this.arrowClickedGoToNextBodyOption()}
                >
                  {bodyPartVariation}/{totalNumberOfVariations}
                </div>
                <div
                  className="col s1 rf_toggle noselect"
                  onClick={() => this.arrowClickedGoToNextBodyOption()}
                >
                  <img
                    src={rightArrow}
                    className="rf_designer_arrow"
                    alt="next body option"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s12 rf_toggle noselect align-center">
                  <InputColor
                    initialHexColor={
                      selectedColors[bodyParts[controllerBodyPart].id]
                    }
                    onChange={color =>
                      this.setColor(color, bodyParts[controllerBodyPart].id)
                    }
                    style={{
                      width: 290,
                      height: 50,
                      backgroundColor: color.hex
                    }}
                  />
                  <div />
                </div>
              </div>
            </div>
          </div>
          {browser === "Safari" && (
            <canvas
              width={canvasWidth}
              height={canvasHeight}
              style={{ display: "none" }}
              ref={r => {
                this._canvas = r
              }}
            />
          )}
          {browser !== "Safari" && (
            <canvas
              width="2800"
              height="2800"
              style={{ display: "none" }}
              ref={r => {
                this._canvas = r
              }}
            />
          )}
        </div>

        <div id="discardModal" className="modal modal-fixed-footer rf_modal">
          <div className="modal-content">
            <h4>Discard design?</h4>
            <p>Are you sure you’d like to discard your design?</p>
          </div>
          <div className="modal-footer">
            <div className="modal-close waves-effect waves-green btn-flat left">
              Cancel
            </div>
            <div
              onClick={this.discard}
              className="modal-close waves-effect waves-green btn-flat"
            >
              Discard
            </div>
          </div>
        </div>
      </>
    )
  }
}

function renderSvgPart(
  part,
  pathClass,
  pathColorStyle,
  position,
  handleClick,
  index
) {
  var style = {}
  if (part.path) {
    if (part.path[position]) {
      if (part.path[position].style) {
        const styleTouples = part.path[position].style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }

      if (part.path[position].id) {
        return (
          <path
            sourceofcode="01"
            onClick={() => handleClick(part.id, index)}
            key={part.id}
            id={part.id}
            className={pathClass}
            d={part.path[position].d}
            style={style}
          />
        )
      } else
        return (
          <GroupOfPaths
            pathColorStyle={pathColorStyle}
            key={part.id}
            onClick={() => handleClick(part.id, index)}
            part={part.path}
            id=""
          />
        )
    } else {
      if (part.path.style) {
        const styleTouples = part.path.style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }
      return (
        <path
          sourceofcode="02"
          onClick={() => handleClick(part.id, index)}
          key={part.id}
          id={part.id}
          className={pathClass}
          d={part.path.d}
          style={style}
        />
      )
    }
  } else {
    return (
      <Group
        key={part.id}
        part={part}
        onClick={() => handleClick(part.id, index)}
        position={position}
        pathColorStyle={pathColorStyle}
      />
    )
  }
}
