import React, { Component } from "react"
import RubyStretch from "./../assets/Rubybot-stretch.gif"
import AnimatedWrapper from "./../utils/AnimatedWrapper"

class LoginScreen extends Component {
  render() {
    return (
      <div className="rf_interface">
        <div className="rf_login valign-wrapper">
          <div className="rf_design">
            <img
              className="rf_login_image"
              src={RubyStretch}
              alt="Ruby Stretch"
            />
            <h3 className="rf_welcome">Welcome to Rubyfornia!</h3>
            <div className="row">
              <form className="col s12">
                <div className="row">
                  <div className="input-field col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <div className="btn" onClick={() => this.props.login()}>
                      Login
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    Subject to Terms and Privacy Policies.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AnimatedWrapper(LoginScreen)
