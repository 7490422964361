import React from "react"

export default function SharePetModal() {
  return (
    <div id="sharePetModal" className="modal modal-fixed-footer rf_modal ">
      <div className="modal-content">
        <div className="center">
          <img
            alt="animation about sharing the pet"
            src="http://haroldl.com/images/Share-Anim.png"
            className="rf_modal-anim center"
          />
        </div>
        <h4>Share</h4>
        <p>
          Send an image of your dog to family and friends. Separate email
          addresses with a comma.
        </p>
        <div className="input-field col s10 offset-s1 m8 offset-m2 l6 offset-l3">
          <input id="rf_shareEmail" type="text" className="validate" />
          <label for="rf_shareEmail">Email</label>
        </div>
      </div>
      <div className="modal-footer">
        <a
          href="#!"
          className="modal-close waves-effect waves-green btn-flat left"
        >
          Cancel
        </a>
        <a
          href="#!"
          className="modal-close waves-effect waves-green btn-flat"
          id="click2share"
        >
          Click to share
        </a>
      </div>
    </div>
  )
}
