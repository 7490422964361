import React, { Component } from "react"
import RubyStretch from "./../assets/addNew.png"
import { Link } from "react-router-dom"

export default class AddNewPet extends Component {
  render() {
    return (
      <div className="col s12 m6 rf_createCard_noclick petInListWrapper">
        <div>
          <img
            className="rf_login_image responsive-img"
            src={RubyStretch}
            alt="Ruby Stretch"
          />
          <Link
            to="/new"
            className="btn-floating btn-large waves-effect waves-light red rf_addButton"
          >
            <i className="material-icons">add</i>
          </Link>
          <div className="rf_addText" style={{ top: "10px" }}>
            Create a dog by tapping the red plus button
          </div>
        </div>
      </div>
    )
  }
}
// <a
//     href="create2.html"
//     className="btn-floating btn-large waves-effect waves-light red rf_addButton"
// >
//     <i className="material-icons">add</i>
// </a>
