import React, { Component } from "react"
import RubyStretch from "./../assets/Rubybot-stretch.gif"
import { closeModal } from "./utils"
import M from "materialize-css/dist/js/materialize.min.js"

export default class AddNewPetModal extends Component {
  state = {
    name: ""
  }
  validateName = () => {
    const { name } = this.state
    if (name === "") {
      M.toast({
        html: "🚨 Please eneter your dog's name",
        displayLength: 1500
      })
    } else {
      closeModal("addNewPetModal")
      this.setState({ name: "" })
      this.props.setNewPetName(name)
    }
  }

  handleKeyUp(event) {
    if (event.key === "Enter") {
      this.validateName()
    }
  }

  handleChange(event) {
    this.setState({ name: event.target.value })
  }

  render() {
    return (
      <div id="addNewPetModal" className="modal modal-fixed-footer rf_modal ">
        <div className="modal-content center">
          <img
            src={RubyStretch}
            className="rf_createImage"
            alt="Ruby Stretch"
          />
          <h5>What’s your dog’s name?</h5>

          <div className="input-field col s10 offset-s1 m8 offset-m2 l6 offset-l3">
            <input
              id="dog_name"
              type="text"
              className="validate"
              onKeyUp={event => this.handleKeyUp(event)}
              onChange={event => this.handleChange(event)}
              value={this.state.name}
            />
            <label htmlFor="dog_name">Dog name</label>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-close waves-effect waves-green btn-flat left">
            Cancel
          </div>
          <div
            onClick={() => this.validateName()}
            className="waves-effect waves-green btn-flat"
          >
            Continue
          </div>
        </div>
      </div>
    )
  }
}
