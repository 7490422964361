import React, { Component } from "react"
import LoadingScreen from "./LoadingScreen"
import { colors } from "./utils"
import breeds from "./../svg-data/breeds.json"
import Group from "./Group"
import GroupOfPaths from "./GroupOfPaths"
import { obj2array } from "./utils"
import camelify from "camelify"

export default class BreedPreview extends Component {
  state = {
    loading: true,
    bodyParts: null,
    selectedParts: false,
    selectedColors: false,
    activePartId: false,
    breed: false,
    breedId: false,
    controllerBodyPart: 0,
    history: [],
    historyPointer: false,
    redoList: [],
    name: false,
    petId: false
  }

  loadBreed = async (
    breedId,
    selectedParts = false,
    selectedColors = false
  ) => {
    this.setState({
      breed: breeds[breedId],
      breedId: breedId,
      selectedColors: false,
      selectedParts: false,
      activePartId: false
    })

    if (breeds[breedId]) {
      const filePath = breeds[breedId].path
      // ./../ svg - data / Asset10.svg.json
      import("./../svg-data/" + filePath + ".json").then(module => {
        const moduleArray = obj2array(module)
        this.setState({ bodyParts: moduleArray })
        if (!selectedParts) {
          selectedParts = {}
          moduleArray.forEach(part => {
            selectedParts[part.id] = 0
          })
        }
        this.setState({ selectedParts: selectedParts })
        if (!selectedColors) {
          selectedColors = []
          moduleArray.forEach(part => {
            selectedColors[part.id] = 0
          })
        }

        this.setState({ selectedColors: selectedColors })
        this.setState({ loading: false })
      })
    }
  }

  componentDidMount = async () => {
    this.setState({ name: "PREVIEW" })
    await this.loadBreed(this.props.breedId)

    // if (this.state.bodyParts) this.randomPet()
    // this.interval = setInterval(() => {
    //   if (this.state.bodyParts) this.randomPet()
    // }, 1050)
  }

  getRandom = (min, max) => {
    return parseInt(Math.random() * (max - min) + min)
  }

  randomPet = () => {
    let newSelectedParts = {}
    let newSelectedColors = {}
    const { bodyParts } = this.state
    bodyParts.forEach(part => {
      newSelectedParts[part.id] = this.getRandom(0, part.partCount)
      newSelectedColors[part.id] = this.getRandom(0, colors.length)
    })
    this.setState({
      selectedParts: newSelectedParts,
      selectedColors: newSelectedColors
    })
    return true
  }
  render() {
    const { breed } = this.props
    const { loading, selectedColors, selectedParts, activePartId } = this.state

    if (loading) {
      return <LoadingScreen />
    }

    return (
      <div className="breedPreviewWrapper">
        <svg
          className="petSVG"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={breed.viewBox}
        >
          {this.state.bodyParts.map((part, index) => {
            var pathColorStyle = colors[0]
            if (selectedColors[part.id]) {
              pathColorStyle = colors[selectedColors[part.id]]
            }
            var pathClass = part.class
            if (part.id === activePartId) pathClass += " active"
            return renderSvgPart(
              part,
              pathClass,
              pathColorStyle,
              selectedParts[part.id],
              this.handleClick,
              index
            )
          })}
        </svg>
        <div className="rf_usersDogName">{breed.name}</div>
      </div>
    )
  }
}

function renderSvgPart(
  part,
  pathClass,
  pathColorStyle,
  position,
  handleClick,
  index
) {
  var style = {}
  if (part.path) {
    if (part.path[position]) {
      if (part.path[position].style) {
        const styleTouples = part.path[position].style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }

      if (part.path[position].id) {
        return (
          <path
            sourceofcode="01"
            key={part.id}
            id={part.id}
            className={pathClass}
            d={part.path[position].d}
            style={style}
          />
        )
      } else
        return (
          <GroupOfPaths
            pathColorStyle={pathColorStyle}
            key={part.id}
            part={part.path}
            id=""
          />
        )
    } else {
      if (part.path.style) {
        const styleTouples = part.path.style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }
      return (
        <path
          sourceofcode="02"
          key={part.id}
          id={part.id}
          className={pathClass}
          d={part.path.d}
          style={style}
        />
      )
    }
  } else {
    return (
      <Group
        key={part.id}
        part={part}
        position={position}
        pathColorStyle={pathColorStyle}
      />
    )
  }
}
