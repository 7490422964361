import React, { Component } from "react"
import "./../css/loading.css"

/**
 *
 *  This SVG was found here:
 *
 *  https://loading.io/spinner/kakidog/
 *
 */

export default class LoadingScreen extends Component {
  render() {
    // return <div />
    return (
      <div className="loaderWrapper">
        <svg
          className="lds-kakidog"
          width="300px"
          height="300px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          {" "}
          <g transform="rotate(357.772 49.9997 49.9997)">
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="360 50 50;0 50 50"
              keyTimes="0;1"
              dur="1.5s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1"
              begin="-0.15000000000000002s"
            />
            <circle
              cx="50"
              cy="50"
              r="39.891"
              stroke="#dec17a"
              strokeWidth="14.4"
              fill="none"
              strokeDasharray="0 300"
            >
              <animate
                attributeName="stroke-dasharray"
                values="15 300;55.1413599195142 300;15 300"
                keyTimes="0;0.5;1"
                dur="1.5s"
                repeatCount="indefinite"
                calcMode="linear"
                keySplines="0 0.4 0.6 1;0.4 0 1 0.6"
                begin="-0.069s"
              />
            </circle>
            <circle
              cx="50"
              cy="50"
              r="39.891"
              stroke="#ffffff"
              strokeWidth="7.2"
              fill="none"
              strokeDasharray="0 300"
            >
              <animate
                attributeName="stroke-dasharray"
                values="15 300;55.1413599195142 300;15 300"
                keyTimes="0;0.5;1"
                dur="1.5s"
                repeatCount="indefinite"
                calcMode="linear"
                keySplines="0 0.4 0.6 1;0.4 0 1 0.6"
                begin="-0.069s"
              />
            </circle>
            <circle
              cx="50"
              cy="50"
              r="32.771"
              stroke="#000000"
              strokeWidth="1"
              fill="none"
              strokeDasharray="0 300"
            >
              <animate
                attributeName="stroke-dasharray"
                values="15 300;45.299378454348094 300;15 300"
                keyTimes="0;0.5;1"
                dur="1.5s"
                repeatCount="indefinite"
                calcMode="linear"
                keySplines="0 0.4 0.6 1;0.4 0 1 0.6"
                begin="-0.069s"
              />
            </circle>
            <circle
              cx="50"
              cy="50"
              r="47.171"
              stroke="#000000"
              strokeWidth="1"
              fill="none"
              strokeDasharray="0 300"
            >
              <animate
                attributeName="stroke-dasharray"
                values="15 300;66.03388996804073 300;15 300"
                keyTimes="0;0.5;1"
                dur="1.5s"
                repeatCount="indefinite"
                calcMode="linear"
                keySplines="0 0.4 0.6 1;0.4 0 1 0.6"
                begin="-0.069s"
              />
            </circle>
          </g>
          <g transform="rotate(0.544159 50 50)">
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="360 50 50;0 50 50"
              keyTimes="0;1"
              dur="1.5s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M97.2,49.3c0.1,6.3-1.1,12.6-3.4,18.4l-13.4-5.4c1.6-4,2.5-8.4,2.4-12.8"
            />
            <path
              fill="#ffffff"
              d="M93.6,49.3l-0.1,3.7l-0.4,3.7c-0.4,2.1-2.3,3.4-4.1,2.9l-0.2-0.1c-1.9-0.5-3-2.3-2.7-4l0.4-3.1l0.1-3.1"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M85.5,62.3c-0.2,0.7-0.5,1.4-0.8,2.1l-0.9,2.1c-0.6,1.1-2,1.5-3.2,0.8c-1.1-0.7-1.7-2-1.1-2.9l0.8-1.8 c0.3-0.6,0.5-1.2,0.7-1.9"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M94.6,65.5c-0.3,0.9-0.6,1.8-1,2.7l-1.1,2.6c-0.8,1.4-2.3,2-3.5,1.3v0c-1.1-0.7-1.5-2.2-0.9-3.4l1-2.4 c0.3-0.8,0.7-1.6,0.9-2.4"
            />
          </g>
          <g transform="rotate(357.772 49.9997 49.9997)">
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="360 50 50;0 50 50"
              keyTimes="0;1"
              dur="1.5s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1"
              begin="-0.15000000000000002s"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M97.2,50.1c0-5-0.8-10-2.4-14.8c-0.4-1.2-1-2.2-1.8-3c-0.7-0.8-1.7-1.4-2.7-1.8c0,0.6-0.3,1.2-0.8,1.5 c-0.8,0.3-1.7,0-2.1-0.8l-0.5-1c-0.6,0.1-1.2,0.3-1.8,0.7c-0.6,0.3-1.1,0.7-1.6,1.2l0.4,0.9c0.4,0.7,0,1.6-0.8,2 c-0.6,0.3-1.2,0.2-1.6-0.1c-0.4,0.8-0.5,1.7-0.7,2.5c-0.1,0.9,0,1.7,0.3,2.5c1,3.3,1.6,6.8,1.6,10.2"
            />
            <path
              fill="#ffffff"
              d="M86.4,50.1c0-1.3-0.1-2.6-0.2-3.8c-0.3-1.7,1-3.4,2.9-3.8l0.3,0c1.9-0.4,3.7,1,4,3.1c0.1,1.5,0.2,3.1,0.2,4.6"
            />
            <path
              fill="#ff9922"
              d="M93.1,34.1c0.1,0.4-0.3,0.8-0.9,1.1c-0.6,0.2-1.2,0.1-1.4-0.2c-0.1-0.3,0.3-0.8,0.9-1.1 C92.4,33.6,93,33.7,93.1,34.1z"
            />
            <path
              fill="#ff9922"
              d="M81.9,38.3c0.1,0.3,0.7,0.3,1.3,0.1c0.6-0.2,1-0.6,0.9-0.9c-0.1-0.3-0.7-0.3-1.3-0.1 C82.2,37.6,81.8,38,81.9,38.3z"
            />
            <path
              fill="#ff9922"
              stroke="#000000"
              d="M87.5,38.5l0.2,0.7c0.1,0.4,0.5,0.7,1,0.6c0.4-0.1,0.7-0.6,0.6-1L89,38"
            />
            <path d="M88.5,36.3c0.1,0.3-0.2,0.7-0.6,0.9c-0.5,0.2-0.9,0-1.1-0.3c-0.1-0.3,0.2-0.7,0.6-0.9C87.9,35.8,88.4,36,88.5,36.3z" />
            <path
              fill="none"
              stroke="#000000"
              d="M85.8,38c0.2,0.5,0.8,0.9,1.4,0.7c0.6-0.2,1.1-0.4,0.6-1.6c0.4,1.2,0.9,1.1,1.5,0.9c0.6-0.2,0.9-0.8,0.7-1.5"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M86.8,42.1c0.2,0.7,0.2,1.5,0.4,2.2c0.1,0.8,0.3,1.5,0.3,2.3c0.1,1.3-0.9,2.3-2.3,2.3h0 c-1.3,0-2.5-0.8-2.5-1.9c0-0.7-0.2-1.3-0.3-2c-0.1-0.7-0.2-1.3-0.3-2"
            />
            <path
              fill="#dec17a"
              stroke="#000000"
              d="M96.1,40.1c0.2,0.9,0.3,1.9,0.5,2.8c0.1,0.9,0.3,1.9,0.4,2.8c0.1,1.6-0.9,2.9-2.2,2.9c-1.3,0-2.5-1.1-2.5-2.5 c0-0.9-0.2-1.7-0.3-2.5c-0.1-0.8-0.2-1.7-0.4-2.5"
            />
            <path
              fill="#000000"
              d="M90.9,33.7c0.2,0.6,0,1.3-0.6,1.5c-0.5,0.2-1.2-0.1-1.4-0.7c-0.2-0.6,0-1.2,0.6-1.5C90,32.7,90.6,33,90.9,33.7z"
            />
            <path
              fill="#000000"
              d="M85.3,35.9c0.2,0.5-0.1,1.1-0.6,1.3c-0.5,0.2-1.1,0-1.3-0.5c-0.2-0.5,0.1-1.1,0.6-1.3C84.5,35.1,85.1,35.4,85.3,35.9z"
            />
            <path
              fill="#8f722f"
              stroke="#000000"
              d="M83.2,34.9c0.8-0.3,1.1-1.2,0.8-2L83.5,32c-0.9,0.8-1.5,1.7-2,2.7C82,35.1,82.6,35.2,83.2,34.9z"
            />
            <path
              fill="#8f722f"
              stroke="#000000"
              d="M89.6,32c0.6-0.3,0.9-0.8,0.8-1.5c-1.1-0.4-2.3-0.5-3.4-0.3l0.5,1C87.8,32,88.8,32.3,89.6,32z"
            />
          </g>
        </svg>
      </div>
    )
  }
}
