import React, { Component } from "react"
import AnimatedWrapper from "./../utils/AnimatedWrapper"
import PetInList from "./PetInList"
import AddNewPet from "./AddNewPet"
import { openModal } from "./utils"

class ListOfYourPets extends Component {
  componentDidMount() {
    const { pets } = this.props
    if (pets.length === 0) {
      openModal("welcomeToRubyforniaModal")
    }
  }
  render() {
    const { pets } = this.props

    return (
      <>
        <div className="container controlsBody">
          <h5>Your pack</h5>
          <div className="row">
            {pets &&
              pets.map((pet, index) => {
                return (
                  <div key={index}>
                    <PetInList pet={pet} position={index} />
                  </div>
                )
              })}
            <AddNewPet />
          </div>
        </div>
      </>
    )
  }
}

export default AnimatedWrapper(ListOfYourPets)
// export default ListOfYourPets
