import React, { Component } from "react"
import { Link } from "react-router-dom"
import GroupOfPaths from "./GroupOfPaths"
import camelify from "camelify"

export default class Group extends Component {
  isFunction = functionToCheck => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    )
  }

  render() {
    const { part, position, pathColorStyle, hasLink } = this.props
    const group = part.g[position]

    if (group && group.path) {
      // console.log('DEBUG GROUP for ' + part.id)
      // console.log(group.path)
      if (!this.isFunction(group.path.map)) {
        // console.log("WRONG CASE!! 😿 " + part.id)
        return <div />
      }
      return (
        <g id={part.id}>
          {group.path.map((part, key) => {
            var style = {}
            if (part.style) {
              const styleTouples = part.style.split(";")
              styleTouples.forEach(styleDuo => {
                const styleParts = styleDuo.split(":")
                style[camelify(styleParts[0].trim())] = styleParts[1].trim()
              })
              if (pathColorStyle !== "") {
                style["fill"] = pathColorStyle
              }
            }
            // console.log({ style })
            if (hasLink)
              return (
                <Link key={key} to={hasLink}>
                  <path
                    onClick={this.props.onClick}
                    key={key}
                    id={part.id}
                    sourceofcode="03"
                    d={part.d}
                    style={style}
                  />
                </Link>
              )

            return (
              <path
                onClick={this.props.onClick}
                key={key}
                id={part.id}
                sourceofcode="03"
                d={part.d}
                style={style}
              />
            )
          })}
        </g>
      )
    } else {
      if (group)
        if (group.g)
          return (
            <GroupOfPaths
              onClick={this.props.onClick}
              pathColorStyle={pathColorStyle}
              part={group.g.path}
              id=""
            />
          )
        else return <React.Fragment />
    }
  }
}
