import React, { Component } from "react"
import breeds from "./../svg-data/breeds.json"
import M from "materialize-css/dist/js/materialize.min.js"

export default class EditPetDetailModal extends Component {
  state = {
    story: "",
    breedName: "",
    image: "",
    name: ""
  }

  componentDidMount = () => {
    setTimeout(() => {
      /*
        console.log("====================================")
        console.log(this.props)
        console.log("====================================")
      */
      const { story, breedId, petName } = this.props.pet
      const breed = breeds[breedId]
      this.setState({ story, name: petName, breedName: breed.name })
      M.updateTextFields()
    }, 50)
  }

  render() {
    const { story, breedName, name } = this.state
    return (
      <div
        id="editPetDetailModal"
        className="modal modal-fixed-footer rf_modal "
      >
        <div className="modal-content">
          <h4>Edit</h4>
          <div className="row">
            <div className="input-field col s12">
              <div className="file-field input-field">
                <div className="btn">
                  <span>Photo</span>
                  <input type="file" />
                </div>
                <div className="file-path-wrapper">
                  <input
                    className="file-path validate"
                    type="text"
                    placeholder="Upload a new file..."
                  />
                </div>
              </div>
            </div>
            <div className="input-field col s12">
              <input
                id="rf_detail_dogName"
                type="text"
                className="validate"
                value={name}
              />
              <label for="rf_detail_dogName">Name</label>
            </div>
            <div className="input-field col s12">
              <input
                id="rf_detail_dogBreed"
                type="text"
                className="validate"
                value={breedName}
              />
              <label for="rf_detail_dogBreed">Breed</label>
            </div>
            <div className="input-field col s12">
              <textarea id="textarea1" className="materialize-textarea">
                {story}
              </textarea>
              <label for="textarea1">Story</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-close waves-effect waves-green btn-flat left">
            Cancel
          </div>
          <div
            className="modal-close waves-effect waves-green btn-flat"
            id="click2save"
          >
            Save
          </div>
        </div>
      </div>
    )
  }
}
