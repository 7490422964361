import React, { Component } from "react"
import { Link } from "react-router-dom"
import camelify from "camelify"

export default class GroupOfPaths extends Component {
  render() {
    const group = this.props.part
    const { id, hasLink, pathColorStyle } = this.props

    return (
      <g id={id}>
        {group.map((part, key) => {
          var style = {}
          if (part.style) {
            const styleTouples = part.style.split(";")
            styleTouples.forEach(styleDuo => {
              const styleParts = styleDuo.split(":")
              style[camelify(styleParts[0].trim())] = styleParts[1].trim()
            })
            if (pathColorStyle !== "") {
              style["fill"] = pathColorStyle
            }
          }

          if (hasLink)
            return (
              <Link key={key} to={hasLink}>
                <path
                  key={key}
                  sourceofcode="04"
                  onClick={this.props.onClick}
                  d={part.d}
                  style={style}
                />
              </Link>
            )

          return (
            <path
              key={key}
              sourceofcode="04"
              onClick={this.props.onClick}
              d={part.d}
              style={style}
            />
          )
        })}
      </g>
    )
  }
}
