import React, { Component } from "react"
import breeds from "./../svg-data/breeds.json"
import Group from "./Group"
import GroupOfPaths from "./GroupOfPaths"
import { obj2array } from "./utils"
import { Link } from "react-router-dom"
import camelify from "camelify"

export default class RubySVGPreview extends Component {
  state = {
    loading: true,
    bodyParts: null,
    selectedParts: false,
    selectedColors: false,
    activePartId: false,
    breed: false,
    breedId: false,
    controllerBodyPart: 0,
    history: [],
    historyPointer: false,
    redoList: [],
    name: false,
    petId: false
    // redirectToEdit: false
  }

  handleClick = event => {
    event.stopPropagation()
    // console.log("====================================")
    // console.log("CLICKED!!! 👨🏿‍🚒")
    // console.log("====================================")
    // this.setState({ redirectToEdit: true })
    return false
  }

  loadBreed = async (
    breedId,
    selectedParts = false,
    selectedColors = false
  ) => {
    this.setState({
      breed: breeds[breedId],
      breedId: breedId,
      selectedColors: false,
      selectedParts: false,
      activePartId: false
    })

    if (breeds[breedId]) {
      const filePath = breeds[breedId].path
      import("./../svg-data/" + filePath + ".json").then(module => {
        const moduleArray = obj2array(module)
        this.setState({ bodyParts: moduleArray })
        if (!selectedParts) {
          selectedParts = {}
          moduleArray.forEach(part => {
            selectedParts[part.id] = 0
          })
        }
        this.setState({ selectedParts: selectedParts })
        if (!selectedColors) {
          selectedColors = []
          moduleArray.forEach(part => {
            selectedColors[part.id] = "#FFFFFF"
          })
        }

        this.setState({ selectedColors: selectedColors })

        this.setState({
          selectedColors: this.props.pet.selectedColors,
          selectedParts: this.props.pet.selectedParts
        })

        this.setState({ loading: false })
      })
    }
  }

  componentDidMount = async () => {
    const { selectedColors, selectedParts } = this.props.pet
    this.setState({ name: "PREVIEW" })
    await this.loadBreed(this.props.pet.breedId)
    this.setState({ selectedColors, selectedParts })
  }

  render() {
    const {
      loading,
      selectedColors,
      selectedParts,
      activePartId,
      breed
    } = this.state

    // if (redirectToEdit) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: this.props.editLink
    //       }}
    //     />
    //   )
    // }

    const { imgClass, editLink } = this.props

    if (loading) {
      return <></>
      //   return <LoadingScreen />
    }

    return (
      <>
        <svg
          className={imgClass}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={breed.viewBox}
        >
          {this.state.bodyParts.map((part, index) => {
            var pathColorStyle = "#FFFFFF"
            if (selectedColors[part.id]) {
              pathColorStyle = selectedColors[part.id]
            }
            var pathClass = part.class
            if (part.id === activePartId) pathClass += " active"
            return renderSvgPart(
              part,
              pathClass,
              pathColorStyle,
              selectedParts[part.id],
              this.handleClick,
              index,
              editLink
            )
          })}
        </svg>
        {/* <Link to={editLink}>Edit</Link> */}
      </>
    )
  }
}

function renderSvgPart(
  part,
  pathClass,
  pathColorStyle,
  position,
  handleClick,
  index,
  editLink
) {
  var style = {}
  if (part.path) {
    if (part.path[position]) {
      if (part.path[position].style) {
        const styleTouples = part.path[position].style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }

      if (part.path[position].id) {
        return (
          <Link key={part.id} to={editLink}>
            <path
              onClick={handleClick}
              sourceofcode="01"
              key={part.id}
              id={part.id}
              className={pathClass}
              d={part.path[position].d}
              style={style}
            />
          </Link>
        )
      } else
        return (
          <GroupOfPaths
            onClick={handleClick}
            pathColorStyle={pathColorStyle}
            key={part.id}
            part={part.path}
            id=""
            hasLink={editLink}
          />
        )
    } else {
      if (part.path.style) {
        const styleTouples = part.path.style.split(";")
        styleTouples.forEach(styleDuo => {
          const styleParts = styleDuo.split(":")
          style[camelify(styleParts[0].trim())] = styleParts[1].trim()
        })
        if (pathColorStyle !== "") {
          style["fill"] = pathColorStyle
        }
      }
      return (
        <Link to={editLink}>
          <path
            onClick={handleClick}
            sourceofcode="02"
            key={part.id}
            id={part.id}
            className={pathClass}
            d={part.path.d}
            style={style}
          />
        </Link>
      )
    }
  } else {
    return (
      <Group
        onClick={handleClick}
        key={part.id}
        part={part}
        position={position}
        pathColorStyle={pathColorStyle}
        hasLink={editLink}
      />
    )
  }
}
