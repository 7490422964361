import M from "materialize-css/dist/js/materialize.min.js"

export const obj2array = obj => {
  let result = []
  Object.keys(obj).forEach((i, element) => {
    result[i] = obj[i]
  })
  return result
}

export const colors = [
  "#f5f1f0",
  "#efebe8",
  "#ffd6a9",
  "#f7c892",
  "#dd945e",
  "#c3804a",
  "#ad8c68",
  "#525764",
  "#47403e",
  "#bc8a6b",
  "#8b5b3a",
  "#d3af97",
  "#efd9cc",
  "#c3ada0",
  "#b55219",
  "#7b3913"
]

export const getRandom = (min, max) => {
  return parseInt(Math.random() * (max - min) + min)
}

export const openModal = modalId => {
  if (!modalId.includes("#")) modalId = "#" + modalId
  M.Modal.init(document.querySelector(modalId), {
    preventScrolling: false
  }).open()
}

export const closeModal = modalId => {
  if (!modalId.includes("#")) modalId = "#" + modalId
  M.Modal.init(document.querySelector(modalId), {
    preventScrolling: false
  }).close()
}

export const uploadeImageAndGetZazzleURL = async (petImage, petName) => {
  const data = new FormData()
  data.append("file", petImage)
  data.append("upload_preset", "rubyfornia")
  const res = await fetch(
    "https://api.cloudinary.com/v1_1/javierezpeleta/image/upload",
    {
      method: "POST",
      body: data
    }
  )
  const file = await res.json()

  const imageURL = file.secure_url
  const imageURL_encoded = encodeURIComponent(imageURL)

  const zazzleURL = `https://www.zazzle.com/api/create/at-238782375704286671?rf=238782375704286671&ax=DesignBlast&sr=250130190962296477&cg=196919359393232312&t__useQpc=false&ed=true&t__smart=false&continueUrl=https%3A%2F%2Fwww.zazzle.com%2Frubyfornia&tc=&ic=&t_name_txt=${encodeURIComponent(
    petName
  )}&t_image_iid=${imageURL_encoded}&t_name_txt=${encodeURIComponent(petName)}`

  // const zazzleURL = `https://www.zazzle.com/api/create/at-238782375704286671?rf=238782375704286671&ax=Linkover&pd=168717452380909255&ed=true&tc=&ic=&t_dogname_txt=${encodeURIComponent(
  //   petName
  // )}&t_dogimage_iid=${imageURL_encoded}`

  return zazzleURL
}

export const detectBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera"
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome"
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari"
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox"
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return "IE"
  } else {
    return "unknown"
  }
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
