import React, { Component } from "react"
import RubyStretch from "./../assets/Rubybot-stretch.gif"

export default class SavePetSuccessModal extends Component {
  render() {
    return (
      <div
        id="savePetSuccessModal"
        className="modal modal-fixed-footer rf_modal "
      >
        <div className="modal-content center">
          <img
            src={RubyStretch}
            alt="Here's Ruby Stretching"
            className="rf_createImage"
          />
          <h5>Success!</h5>
          <p>
            Woof! Woof!
            <br />
            Your dog has been successfully saved!
          </p>
        </div>
        <div className="modal-footer">
          <div
            onClick={() => this.props.redirectToHome()}
            className="modal-close waves-effect waves-green btn-flat"
          >
            OK
          </div>
        </div>
      </div>
    )
  }
}
