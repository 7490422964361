import { combineReducers } from "redux"
import {
  SET_PET_NAME,
  SET_BODY_PART_SHAPE,
  SET_BODY_PART_STYLE
} from "../actions"
import { bodyParts } from "./../components/BodyParts"

function getAllIdsFromMasterSVG() {
  let defaultBodyPartValues = []
  bodyParts.forEach(part => {
    defaultBodyPartValues[part.id] = 0
  })
  return defaultBodyPartValues
}

function petName(state = "", action) {
  switch (action.type) {
    case SET_PET_NAME:
      return action.entry.petName
    default:
      return state
  }
}

function bodyPartShapes(state = getAllIdsFromMasterSVG(), action) {
  switch (action.type) {
    case SET_BODY_PART_SHAPE:
      const { entry } = action
      let newState = { ...state }
      newState[entry.partId] = parseInt(entry.shapeId, 0)
      return newState
    default:
      return state
  }
}

function bodyPartStyles(state = getAllIdsFromMasterSVG(), action) {
  switch (action.type) {
    case SET_BODY_PART_STYLE:
      const { entry } = action
      let newState = { ...state }
      newState[entry.partId] = parseInt(entry.styleId, 0)
      return newState
    default:
      return state
  }
}

export default combineReducers({
  bodyPartShapes,
  bodyPartStyles,
  petName
})
