import React, { Component } from "react"
import M from "materialize-css/dist/js/materialize.min.js"
import axios from "axios"
import { closeModal } from "./utils"

export default class RequestCreationModalForm extends Component {
  state = {
    image: null,
    largeImage: null,
    email: "",
    isProcessing: false,
    isSending: false
  }

  updateEmail = e => {
    this.setState({ email: e.target.value })
  }

  submitRequest = () => {
    const { largeImage, email, isProcessing, isSending } = this.state

    if (isProcessing) {
      M.toast({
        html: "🖼 Processing photo.. please wait.",
        displayLength: 1500
      })
      return false
    }

    if (isSending) {
      M.toast({
        html: "💌 Sending email.. please wait.",
        displayLength: 1500
      })
      return false
    }

    if (!largeImage) {
      M.toast({ html: "🚨 Please upload a valid image", displayLength: 1500 })
      return false
    }

    this.setState({ isSending: true })

    axios
      // .post("http://localhost:3001/contentRequest", {
      .post("https://boiling-brushlands-27433.herokuapp.com/contentRequest", {
        email: email,
        imageURL: largeImage
      })
      .then(function(response) {
        closeModal("requestCreationModalForm")
      })
      .catch(function(error) {
        closeModal("requestCreationModalForm")
      })

    this.setState({ isSending: false })

    return false
  }

  uploadFile = async e => {
    this.setState({ isProcessing: true })
    const files = e.target.files
    const data = new FormData()
    data.append("file", files[0])
    data.append("upload_preset", "sickfits")
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/javierezpeleta/image/upload",
      {
        method: "POST",
        body: data
      }
    )
    const file = await res.json()

    this.setState({
      isProcessing: false,
      image: file.secure_url,
      largeImage: file.eager[0].secure_url
    })
  }

  componentDidMount() {
    this.setState({ email: this.props.user.email })
  }

  render() {
    const { image, isProcessing, isSending } = this.state
    let submitButtonText = "Submit request"
    if (isProcessing) submitButtonText = "Processing..."
    if (isSending) submitButtonText = "Sending..."
    return (
      <div
        id="requestCreationModalForm"
        className="modal modal-fixed-footer rf_modal rf_w2rmodal"
      >
        <div className="modal-content">
          <div className="center">
            <img
              alt="animated Ruby"
              src="http://haroldl.com/rf/images/Request-Anim.png"
              className="rf_modal-anim center"
            />
          </div>
          <h4>Request</h4>
          <p>Capture the personality of your pooch in Rubyfornia!</p>
          <div className="input-field col s12">
            <div className="file-field input-field">
              <div className="btn">
                <span>Photo</span>
                <input type="file" onChange={this.uploadFile} />
              </div>
              <div className="file-path-wrapper">
                <input
                  className="file-path validate"
                  type="text"
                  placeholder="Select photo..."
                />
              </div>
              {isProcessing && <div> uploading image... </div>}
              {image && (
                <img
                  alt="preview of the uploaded dog"
                  src={image}
                  style={{
                    maxWidth: "120px",
                    maxHeight: "120px"
                  }}
                />
              )}
            </div>
          </div>
          <div className="input-field col s10 offset-s1 m8 offset-m2 l6 offset-l3">
            <input
              id="rf_shareEmail"
              type="text"
              className="validate"
              value={this.state.email}
              placeholder="Email"
              onChange={this.updateEmail}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-close waves-effect waves-green btn-flat left">
            Cancel
          </div>
          <div
            className="waves-effect waves-green btn-flat"
            id="click2share"
            onClick={() => this.submitRequest()}
          >
            {submitButtonText}
          </div>
        </div>
      </div>
    )
  }
}
