import firebase from "./../components/client.js"

export const getPetById = petId => {
  return firebase
    .database()
    .ref("pets/" + petId)
    .once("value")
    .then(snapshot => snapshot.val())
}

export const deletePetById = petId => {
  return firebase
    .database()
    .ref("pets/" + petId)
    .remove()
}

export const addPet = values => {
  return firebase
    .database()
    .ref("pets")
    .push(values)
    .then(element => console.log("DONE!! 🎉"))
}

export const objectToArray = obj => {
  if (obj)
    return Object.keys(obj).map(key => {
      obj[key].id = key
      return obj[key]
    })
  else return []
}

export const getPetsByUserId = userId =>
  firebase
    .database()
    .ref("pets")
    .orderByChild("userId")
    .equalTo(userId)
    .once("value")
    .then(snapshot => {
      const array = objectToArray(snapshot.val())
      return array.reverse()
    })
export const getAllPets = userId =>
  firebase
    .database()
    .ref("pets")
    .once("value")
    .then(snapshot => {
      const array = objectToArray(snapshot.val())
      return array.reverse()
    })

export const updatePetById = (id, data) => {
  return firebase
    .database()
    .ref("pets")
    .child(id)
    .update(data)
    .then(ref => ref.once("value"))
    .then(snapshot => snapshot.val())
    .catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    }))
}

export const addUser = (userId, values) => {
  // return firebase.child("users/" + userId).set(values, callback)
  return firebase
    .database()
    .ref()
    .child("users")
    .child(userId)
    .set(values)
}

export const updateUserById = (id, data) => {
  return firebase
    .database()
    .ref("users")
    .child(id)
    .update(data)
    .then(ref => ref.once("value"))
    .then(snapshot => snapshot.val())
    .catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    }))
}

export const getUserById = userId => {
  return firebase
    .database()
    .ref("users/" + userId)
    .once("value")
    .then(snapshot => snapshot.val())
}
