import React, { Component } from "react"
import Main from "./Main"
import All from "./All"
import Screens from "./Screens"
import firebase from "firebase/app"
import { provider, auth } from "./client"
import LoginScreen from "./LoginScreen"
import PetDetail from "./PetDetail"
import Header from "./Header"
import NewPet from "./NewPet"
import WelcomeToRubyforniaModal from "./WelcomeToRubyforniaModal"
import { Switch, Route, withRouter } from "react-router-dom"
import AddNewPetModal from "./AddNewPetModal"
import CreatePetIntroModal from "./CreatePetIntroModal"
import RequestCreationModalForm from "./RequestCreationModalForm"
import DesignerIntroModal from "./DesignerIntroModal"
import SavePetSuccessModal from "./SavePetSuccessModal"
import { HeaderContext } from "./HeaderContext"
import { getUserById, updateUserById, addUser } from "./../utils/rubyAPI"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: false,
      loading: true,
      newPetName: false,
      headerType: "home"
    }
  }

  setNewPetName = name => {
    this.setState({ newPetName: name })
  }

  async componentWillMount() {
    const thisClass = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        thisClass.setState({ user })
        getUserById(user.uid).then(userData => {
          const userDataToInsert = {
            email: user.email,
            id: user.uid,
            photoURL: user.photoURL,
            displayName: user.displayName
          }
          if (!userData) {
            addUser(user.uid, userDataToInsert)
          } else {
            updateUserById(user.uid, userDataToInsert)
          }
        })
      } else {
        // console.log("no user")
      }
      thisClass.setState({ loading: false })
    })
  }
  async login() {
    const result = await auth().signInWithPopup(provider)
    this.setState({ user: result.user })
  }

  async logout() {
    await auth().signOut()
    this.setState({ user: null })
    this.props.history.push("/")
  }

  redirectToHome = () => {
    this.props.history.push("/")
  }

  updateHeaderType = type => {
    this.setState({ headerType: type })
  }

  render() {
    const { history } = this.props
    const { user, loading, newPetName, headerType } = this.state
    if (loading) {
      return <div>{/* <LoadingScreen /> */}</div>
    }
    if (!user) {
      return <LoginScreen login={this.login.bind(this)} />
    }
    return (
      <>
        <HeaderContext.Provider
          value={{
            headerType,
            updateHeaderType: this.updateHeaderType
          }}
        >
          <Header user={user} logout={() => this.logout()} />
          <HeaderContext.Consumer>
            {({ updateHeaderType }) => (
              <Route
                render={({ location }) => {
                  return (
                    <Route
                      location={location}
                      render={() => (
                        <Switch>
                          <Route
                            exact
                            path="/"
                            render={() => (
                              <Main
                                user={user}
                                logout={this.logout.bind(this)}
                                userId={user.uid}
                                setNewPetName={this.setNewPetName}
                                updateHeaderType={updateHeaderType}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/all"
                            render={() => (
                              <All
                                user={user}
                                logout={this.logout.bind(this)}
                                userId={user.uid}
                                setNewPetName={this.setNewPetName}
                                updateHeaderType={updateHeaderType}
                              />
                            )}
                          />
                          <Route
                            path="/pet/:id"
                            render={({ match }) => (
                              <PetDetail
                                petId={match.params.id}
                                user={user}
                                logout={this.logout.bind(this)}
                                history={history}
                                updateHeaderType={updateHeaderType}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/new"
                            render={() => (
                              <NewPet
                                userId={user.uid}
                                user={user}
                                logout={this.logout.bind(this)}
                                history={history}
                                name={newPetName}
                                updateHeaderType={updateHeaderType}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/edit/:id"
                            render={({ match }) => (
                              <NewPet
                                user={user}
                                petId={match.params.id}
                                userId={user.uid}
                                history={history}
                                loading="true"
                                updateHeaderType={updateHeaderType}
                              />
                            )}
                          />
                          <Route exact path="/screens" component={Screens} />
                        </Switch>
                      )}
                    />
                  )
                }}
              />
            )}
          </HeaderContext.Consumer>

          <AddNewPetModal setNewPetName={this.setNewPetName} />
          <WelcomeToRubyforniaModal />
          <CreatePetIntroModal />
          <RequestCreationModalForm user={user} />
          <DesignerIntroModal />
          <SavePetSuccessModal redirectToHome={this.redirectToHome} />
        </HeaderContext.Provider>
      </>
    )
  }
}

// export default withRouter(AnimatedWrapper(App))
export default withRouter(App)
