import React, { Component } from "react"
import BreedPreview from "./BreedPreview"

export default class BreedPreviewSelector extends Component {
  render() {
    const { breed, breedId, askForTheName, setBreed } = this.props

    return (
      <div
        className="col s6 m4 breedSelectorCard"
        onClick={() => {
          askForTheName()
          setBreed(breedId)
        }}
      >
        <BreedPreview breed={breed} breedId={breedId} />
      </div>
    )
  }
}
