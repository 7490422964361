export const SET_BODY_PART_SHAPE = "SET_BODY_PART_SHAPE"
export const SET_BODY_PART_STYLE = "SET_BODY_PART_STYLE"
export const SET_PET_NAME = "SET_PET_NAME"

export function setPetName(petName) {
  const entry = {
    petName: petName
  }
  return {
    type: SET_PET_NAME,
    entry
  }
}

export function setBodyPartShape(partId, shapeId) {
  const entry = {
    partId: partId,
    shapeId: shapeId
  }
  return {
    type: SET_BODY_PART_SHAPE,
    entry
  }
}

export function setBodyPartStyle(partId, styleId) {
  const entry = {
    partId: partId,
    styleId: styleId
  }
  return {
    type: SET_BODY_PART_STYLE,
    entry
  }
}
