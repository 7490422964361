export const bodyParts = [
  {
    id: "LEG_RIGHT",
    class: ["cls-3"],
    d: [
      "M268.25,334.08c-5.44-1-8.71,7.77-4.67,11.3,4.29,3.75,13,2.48,15.3,1s3.32-8,2.91-14.13c-.62-9.17-4-27.2-3-30.46s7.35-10.66,8.54-16.58,2.17-17.21,1.74-19.92-29-5.21-29.69,2.76-4,29.44-1.54,34.17,4.29,6.4,6.32,9.92C266.5,316.22,270.4,328.58,268.25,334.08Z",
      "M270.83,307.49c-1.21-1.69-3-8.2-4.12-10.63-2.94-6.41-3.49-22.46-2.89-29.34.34-3.84,3.56-8.53,17-9a64.39,64.39,0,0,1,11.87.69c3.91.63,8.13,1.6,8.72,5.27.55,3.46-.57,11.33-1.77,17.33a57.6,57.6,0,0,1-3.31,10.39c-.53,1.34-1,2.5-1.28,3.45-.28,1.82.8,13.84,1.52,19.16.68,5,1.37,10.19,1.63,14,.16,2.36.68,14.31-4.75,17.77-1.83,1.17-5.39,2.08-9.22,2.08s-8-.92-10.87-3.44c-3.33-2.91-4.13-8.1-2-12.62a10,10,0,0,1,5.84-5.36c.06-4.06-1.45-11.58-4.15-16.27A41.46,41.46,0,0,0,270.83,307.49Z",
      "M263.83,302.49c-1.21-1.69-3-8.2-4.12-10.63-2.94-6.41-3.49-22.46-2.89-29.34.34-3.84,3.56-8.53,17-9a64.39,64.39,0,0,1,11.87.69c3.91.63,8.13,1.6,8.72,5.27.55,3.46-.57,11.33-1.77,17.33a57.6,57.6,0,0,1-3.31,10.39c-.53,1.34-1,2.5-1.28,3.45-.28,1.82.8,13.84,1.52,19.16.68,5,1.37,10.19,1.63,14,.16,2.36.68,14.31-4.75,17.77-1.83,1.17-5.39,2.08-9.22,2.08s-8-.92-10.87-3.44c-3.33-2.91-4.13-8.1-2-12.62a10,10,0,0,1,5.84-5.36c.06-4.06-1.45-11.58-4.15-16.27A41.46,41.46,0,0,0,263.83,302.49Z",
      "M263.83,302.49c-1.21-1.69-3-8.2-4.12-10.63-2.94-6.41-3.49-22.46-2.89-29.34.34-3.84,3.56-8.53,17-9a64.39,64.39,0,0,1,11.87.69c3.91.63,8.13,1.6,8.72,5.27.55,3.46-.57,11.33-1.77,17.33a57.6,57.6,0,0,1-3.31,10.39c-.53,1.34-1,2.5-1.28,3.45-.28,1.82.8,13.84,1.52,19.16.68,5,1.37,10.19,1.63,14,.16,2.36.68,14.31-4.75,17.77-1.83,1.17-5.39,2.08-9.22,2.08s-8-.92-10.87-3.44c-3.33-2.91-4.13-8.1-2-12.62a10,10,0,0,1,5.84-5.36c.06-4.06-1.45-11.58-4.15-16.27A41.46,41.46,0,0,0,263.83,302.49Z",
      "M264.37,266.86c.34-3.84,3.56-8.53,17-9a64.39,64.39,0,0,1,11.87.69c3.91.63,8.13,1.6,8.72,5.27.55,3.46-.57,11.33-1.77,17.33a57.6,57.6,0,0,1-3.31,10.39c-.53,1.34-1,2.5-1.28,3.45-.28,1.82.8,13.84,1.52,19.16.68,5-5.7,28.29-11.12,31.76-1.83,1.17-5.39,2.08-9.22,2.08s-8-.92-10.87-3.44c-3.33-2.91-4.13-8.1-2-12.62,1.3-2.7,6.41-4.58,8.85-5.36,2.88-4.45,3.92-16.82.85-21.28C260,285.45,263.77,273.73,264.37,266.86Z",
      "M273.44,336.32c-6-1.08-9.6,8.57-5.15,12.46,4.73,4.13,14.33,2.73,16.87,1.11s3.66-8.82,3.21-15.58c-.68-10.11-.18-32.18.88-35.78s3.69-8.44,5-15,2.4-24.48,1.92-27.47-31.95-5.75-32.72,3,0,34.65,2.71,39.86,4.73,7.05,7,10.94C275.72,314.44,275.81,330.27,273.44,336.32Z",
      "M266.54,324.74c-5.44-1-8.71,7.77-4.67,11.3,4.29,3.75,13,2.48,15.3,1s3.32-8,2.91-14.13c-.62-9.17-4-27.2-3-30.46s3.35-7.65,4.53-13.57,2.17-17.21,1.74-19.92-29-5.21-29.69,2.76,0,26.44,2.46,31.17,4.29,6.4,6.32,9.92C264.79,306.89,268.69,319.24,266.54,324.74Z",
      "M299.1,309.07c-6-1.07-9.55,8.52-5.12,12.39,4.7,4.11,14.25,2.72,16.78,1.1s3.64-14.87,3.19-21.59c-.68-10.05-6.27-19.06-5.23-22.63S316.89,258,316.42,255s-36.17-5.71-36.93,3C278.89,264.76,302.15,301.29,299.1,309.07Z",
      "M286.17,266.58s2.53,19.9-.9,30.2-9.21,27.15-12.51,28.14c-4.6,1.38-14.61,1.68-15.28-5.94s8.42-7.89,8.42-7.89,4.11-9.51,3.43-17.06-4.8-27.45-4.8-27.45Z"
    ]
  },
  {
    id: "FOOT_RIGHT",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "TAIL",
    class: ["cls-2"],
    d: [
      "M312,188.8c-7.53-12.39-7.5-40,6.5-27,4.61,4.28,4-13.58,13.5-7.5,21,13.5-1.5,28,16.5,33,7.53,2.09-8.31,35.26-11.19,43.15-5.36,14.67-18.62,26.62-34.75,26.81,0,0-.15-13.43,0-13.43h0C311.15,247.09,336,228.3,312,188.8Z",
      "M334.71,208.2c-2.76-6.75-8.35-30.19-7.7-35s22.65-5.73,28.33,2.14S357,193.59,357,202.85s-.65,18.44-3.87,27.25c-6,16.39-20.8,29.74-38.81,29.94-.05,0-.17-15,0-15h0C323.89,248.69,346,235.83,334.71,208.2Z",
      "M304.2,247c0-.89,0-1.64,0-2.17.12-4.32,3.1-4.69,4-4.7a4,4,0,0,1,1.46.26c2.9,1.1,8.64-.39,12.84-4.71,3.55-3.64,8.59-12,2.48-26.88-2.75-6.72-8.77-31-8-37.06.28-2.09,1.8-4.88,7.42-6.29,8.07-2,22.73-.83,28.11,6.62,4.95,6.86,3.94,15,3.05,22.11a67,67,0,0,0-.67,7.78c0,9.12-.57,18.9-4.12,28.61-7,19.27-24.13,32.36-42.57,32.57-4,0-4-3.91-4-5.79,0-1.13,0-2.66,0-4.29C304.19,250.93,304.19,248.69,304.2,247Z",
      "M344.61,226.22c4.39-8.39-3-25.5,4.5-33,15.42-15.42,30.19,11.14,30,20.5s-8,19-18.5,27.5-51,20.77-64.81,11.1c0,0,8.45-12,8.58-11.94h0C309.6,248.53,336.22,242.25,344.61,226.22Z",
      "M301.68,247.13c14.44-5.73,30.27-15.91,37.76-25.62s17.33-29.18,16.26-40,11.71-3.27,12.85,5.9c1,7.63-2.59,18.55-12,33.3s-29.72,23.54-31.6,24.09-12.23,10.75-13.48,10.38S296.22,251.47,301.68,247.13Z",
      "M304.65,251c17.55-6.27,38.07-18.32,49.38-30.52s18.31-35.74,6.85-44.83c-19.33-15.33,9.33-33.33,23.33,0,4,9.51,3.7,26.68-11.34,45.39s-40,28.5-42.2,29.06-16.8,13.15-18,12.53S297.3,256.22,304.65,251Z",
      "M347.25,223.73c4.39-8.39-3-25.5,4.5-33,15.42-15.42,30.19,11.14,30,20.5s-8,19-18.5,27.5-51,20.77-64.81,11.1c0,0,8.45-12,8.58-11.94h0C312.24,246,338.86,239.75,347.25,223.73Z",
      "M337.29,230.45c10.25,12-12.34,24-30.35,24.23-.05,0,2.68-8,2.68-8C345,236,333.25,225.72,337.29,230.45Z",
      "M307.07,253.06s17.07-6.89,20.51-25.62a249,249,0,0,0,4.48-42.73c.28-9.46,9.48-5.86,12.85-.41A79.73,79.73,0,0,1,354,208.1c1.58,8.57,1.7,25.55-5.81,33.5s-23.77,17.76-30.26,18.3S307.07,253.06,307.07,253.06Z"
    ]
  },
  {
    id: "PATCH_TAIL",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "BODY",
    class: ["cls-3"],
    d: [
      "M178.06,238.7c-3.8,6.19-5.92,22.38-6.33,27a95,95,0,0,0,3,25.46c4.19,16.3,6.84,37.85,3.72,40.6-9-.53-10.06,14.68-5,16.88s13.9,1.72,16.84-.28,3.79-6.95,4-17-4-38.64-2-38.47c6.84,4,16.84,3.56,21.78,2.57,1.77,2.66,10,30.47,4.75,42.31-2.75-.12-12.32,5.95-4.52,14.41,2.88,3.13,15.49,3.61,18.11-.42s2.57-20.77,3-26.08-2.35-23.78-2-27.76c3.24.28,20.32-2.74,27.73-7.11s17.4-15.29,25.12-14.62,8.56,2.5,8.56,2.5-7.55,18.67-3.93,26c1.19,2.41,7,10.1,9,17.18a28.26,28.26,0,0,1-.41,15.19c-9.89-1.7-10,10.5-7.18,13.26s14.46,2,18-.68,5.67-20.84,3-32.28c-2.72-11.62,2.82-22.93,7.11-29s11.26-19.76,9.42-29.29-3.72-18.56-8.28-22-19.47-6.89-27.94-7.63-29.08,3.48-35,2.29-30.32-5.33-34.19-7-39.54-7.66-41.27-3.13S182,232.37,178.06,238.7Z",
      "M169.33,335.46c1.26-5.26,4.67-8.81,9-9.66,1.2-4.54-2.4-19.38-6.1-33.79a97.45,97.45,0,0,1-3.08-26.81c.07-.79.11-1.65.16-2.55.24-4.63.57-11,4.23-16.93,2-3.31,5.86-7.48,9.56-11.5,3.1-3.38,7.36-8,8-9.69a6.83,6.83,0,0,1,4-3.75c7.16-3,27.79-1.72,32.21.2,2.49,1.08,18.44,4,26.1,5.4,3.17.58,12.81,1.06,14.29,1.36,2.4.48,9.78-.38,15.72-1.08,7-.82,14.29-.9,18.85-1.28,11.39-.93,24.16,4,30,8.41,5.52,4.13,7.55,12.73,9.81,24.41,2.18,11.26-5.64,26.09-10.08,32.36-4,5.58-4.76,13-2.48,22.77a66.13,66.13,0,0,1,.91,20.74c-.85,8.28-2.65,13.53-5.35,15.62-4.56,3.53-18.45,5-23.19.39-3.12-3-3.76-9.82-1.36-14.54a10.08,10.08,0,0,1,8.2-5.69,22.48,22.48,0,0,0-.31-10c-1.28-4.61-4.53-9.74-6.69-13.14-.92-1.45-1.51-2.53-2-3.33-4.67-7.71-1.92-21.28-1-26.63a28.33,28.33,0,0,0-4.41-.68c-3.71-.3-9.79,4.43-14.67,8.25-2.83,2.22-12.51,4.31-15.07,5.82-6.32,3.73-18.92,4.58-25.95,5.42-.08,2.16-.16,8.91-.23,11.6-.16,5.79,2.73,16.79,3.14,22,.83,10.57,1,19-1.38,22.69-2.14,3.31-10.58,4.34-13.93,4.63-.66.06-1.43.09-2.26.09-4,0-7.48-.79-10.22-3.76-5.42-5.89-4.23-11.08-3-13.66a14.09,14.09,0,0,1,7.52-6.86c2.91-9.28-3.69-32-5.4-38.49a40.9,40.9,0,0,1-16.18-1.34c-.21,4.94,1.19,19.26,2,24.75.52,3.61,1.85,9.27,2.06,12.15.81,10.92-.17,17.08-4.74,20.19-4.35,3-15.6,3.29-21.68.65C169.3,348,168,341,169.33,335.46Z",
      "M162.33,330.46c1.26-5.26,4.67-8.81,9-9.66,1.2-4.54-2.4-19.38-6.1-33.79a97.45,97.45,0,0,1-3.08-26.81c.07-.79.11-1.65.16-2.55.24-4.63.57-11,4.23-16.93,2-3.31,5.86-7.48,9.56-11.5,3.1-3.38,7.36-8,8-9.69a6.83,6.83,0,0,1,4-3.75c7.16-3,27.79-1.72,32.21.2,2.49,1.08,18.44,4,26.1,5.4,3.17.58,12.81,1.06,14.29,1.36,2.4.48,9.78-.38,15.72-1.08,7-.82,14.29-.9,18.85-1.28,11.39-.93,24.16,4,30,8.41,5.52,4.13,7.55,12.73,9.81,24.41,2.18,11.26-5.64,26.09-10.08,32.36-4,5.58-4.76,13-2.48,22.77a66.13,66.13,0,0,1,.91,20.74c-.85,8.28-2.65,13.53-5.35,15.62-4.56,3.53-18.45,5-23.19.39-3.12-3-3.76-9.82-1.36-14.54a10.08,10.08,0,0,1,8.2-5.69,22.48,22.48,0,0,0-.31-10c-1.28-4.61-4.53-9.74-6.69-13.14-.92-1.45-1.51-2.53-2-3.33-4.67-7.71-1.92-21.28-1-26.63a28.33,28.33,0,0,0-4.41-.68c-3.71-.3-9.79,4.43-14.67,8.25-2.83,2.22-12.51,4.31-15.07,5.82-6.32,3.73-18.92,4.58-25.95,5.42-.08,2.16-.16,8.91-.23,11.6-.16,5.79,2.73,16.79,3.14,22,.83,10.57,1,19-1.38,22.69-2.14,3.31-10.58,4.34-13.93,4.63-.66.06-1.43.09-2.26.09-4,0-7.48-.79-10.22-3.76-5.42-5.89-4.23-11.08-3-13.66a14.09,14.09,0,0,1,7.52-6.86c2.91-9.28-3.69-32-5.4-38.49a40.9,40.9,0,0,1-16.18-1.34c-.21,4.94,1.19,19.26,2,24.75.52,3.61,1.85,9.27,2.06,12.15.81,10.92-.17,17.08-4.74,20.19-4.35,3-15.6,3.29-21.68.65C162.3,343,161,336,162.33,330.46Z",
      "M170.88,334.8a13.11,13.11,0,0,1,10-9.66c1.2-4.54-2.43-22.24-7.38-36.27-4.5-12.75-4-22.13-3.8-24.33.07-.79.11-1.65.16-2.55.24-4.63.57-11,4.23-16.93,2-3.31,5.86-7.48,9.56-11.5,3.1-3.38,7.36-8,8-9.69a6.83,6.83,0,0,1,4-3.75c7.16-3,27.79-1.72,32.21.2,2.49,1.08,18.44,4,26.1,5.4,3.17.58,12.81,1.06,14.29,1.36,2.4.48,9.78-.38,15.72-1.08,7-.82,14.29-.9,18.85-1.28,11.39-.93,24.16,4,30,8.41,5.52,4.13,7.55,12.73,9.81,24.41,2.18,11.26-5.64,26.09-10.08,32.36-4,5.58-4.76,13-2.48,22.77,1.34,5.72-9.75,34.27-12.45,36.36-4.56,3.53-18.45,5-23.19.39-3.12-3-3.76-9.82-1.36-14.54,1.69-3.34,5.58-5.31,9.2-5.69,3.08-5.75,4.74-15.75,0-23.17-.92-1.45-1.48-2.56-2-3.33-7.26-10.91-1.92-21.28-1-26.63a22.77,22.77,0,0,0-4.41-.68c-15.53-.94-18.86,9.06-29.74,14.08-6.66,3.07-18.92,4.58-25.95,5.42-.08,2.16-.65,8.94-.23,11.6,1.07,6.87,2.73,16.79,3.14,22,.83,10.57,1,19-1.38,22.69-2.14,3.31-10.58,4.34-13.93,4.63-.66.06-1.43.09-2.26.09-4,0-7.48-.79-10.22-3.76-5.42-5.89-4.23-11.08-3-13.66a14.09,14.09,0,0,1,7.52-6.86c2.91-9.28-3.69-32-5.4-38.49a40.9,40.9,0,0,1-16.18-1.34c-.21,4.94,4.83,34,5,36.9.81,10.92-.17,17.08-4.74,20.19-4.35,3-15.6,3.29-21.68.65C170.85,347.34,169.57,340.31,170.88,334.8Z",
      "M167.56,237.55c-4.19,6.82-3.75,14.42-4.2,19.55-.19,2.18-.17,20.16,3.27,33.58,4.61,18,10.23,41.72,6.79,44.75-9.91-.59-11.09,16.17-5.5,18.6s15.32,1.89,18.55-.31,4.17-7.65,4.4-18.7-7.08-42.59-4.89-42.39c7.54,4.44,18.56,3.93,24,2.84,2,2.94,8.77,33.59,2.92,46.63-3-.13-13.57,6.56-5,15.88,3.17,3.45,17.08,4,20-.47s1.83-22.89,2.34-28.75.72-27.21,1.1-31.6c3.57.31,22.39-2,30.56-6.84s19.18-16.85,27.68-16.11S299,277,299,277s-5.91,17.27-1.92,25.36c1.31,2.66,4.5,12.33,6.66,20.14a35.36,35.36,0,0,1,.55,16.74c-10.9-1.88-11.06,11.57-7.92,14.61s14.94,2.23,18.79-.75,6.25-25.17,3.3-37.78c-3-12.8,3.9-21,8.64-27.65s16.64-41.07-5.17-56.43-72.71-17.2-79.23-18.5-17.12,1.16-21.39-.68-32.14-3.55-34.05,1.45S171.84,230.57,167.56,237.55Z",
      "M172,226.62c-3.8,6.19-5.58,28.13-6,32.78a95,95,0,0,0,3,25.46c4.19,16.3,3.84,35.85.72,38.6-9-.53-10.06,14.68-5,16.88s13.9,1.72,16.84-.28,3.79-6.95,4-17-1-36.64,1-36.47c6.84,4,16.84,3.56,21.78,2.57,1.77,2.66,7,28.47,1.74,40.31-2.75-.12-12.32,5.95-4.52,14.41,2.88,3.13,15.49,3.61,18.11-.42s2.57-20.77,3-26.08.65-21.78,1-25.76c3.24.28,20.32-2.74,27.73-7.11s17.4-15.29,25.12-14.62,8.56,2.5,8.56,2.5-3.55,15.67.08,23c1.19,2.41,7,10.1,9,17.18a28.26,28.26,0,0,1-.41,15.19c-9.89-1.7-10,10.5-7.18,13.26s14.46,2,18-.69,5.67-20.84,3-32.28c-2.72-11.62-1.18-19.93,3.11-26s11.26-19.76,9.42-29.29-3.72-18.56-8.28-22-19.47-6.89-27.94-7.63-29.08,3.48-35,2.29-30.32-5.33-34.19-7-29.16-3.22-30.9,1.31S175.9,220.29,172,226.62Z",
      "M179,220.64c-4.17,6.79-6.12,30.84-6.57,35.94a104.17,104.17,0,0,0,3.25,27.91c1.15,4.47,3,8,3.54,10.81,1.67,8.46,1.81,15.6-.75,17.86-9.85-.59-11,16.09-5.47,18.5s15.24,1.88,18.46-.31,4.15-7.61,4.38-18.6c.08-3.91-.34-11.72-.42-15.05-.15-6-1.87-11.4-.47-11.27,7.5,4.42,18.46,3.91,23.87,2.82a26.41,26.41,0,0,1,3.86,9c1,4.4,3,15.05,0,21.54-3-.13-13.5,6.52-5,15.8,3.16,3.43,17,4,19.85-.46s3.15-22.75,3.33-28.59c.13-4.49-1.29-10.22-.91-14.59,3.55.31,27.76-5,35.88-9.8s23.46-16.76,31.92-16,7.19.55,7.19.55-.86,10.48,5.72,17.61a26.37,26.37,0,0,1,6.37,12c2.15,7.76,1.18,13.28.65,15.07-10.84-1.87-11,11.51-7.87,14.53s15.85,2.21,19.69-.75,5.12-24.26,2.18-36.81c-3-12.74,13.83-14.42,10.77-42-3.29-29.6-27.46-28.64-36.74-29.45s-36.27,3.82-42.76,2.51-38.72-5.84-43-7.68-32-3.53-33.87,1.44S183.29,213.69,179,220.64Z",
      "M211.82,220.42s15.1-2.06,24-1.37,17.16,2.75,36.38,8.24,30.92,8.05,39.67,12.32c6.81,3.31,18.31,12.39,14.88,29.55a129.62,129.62,0,0,1-15.1,40.49c-6.61,11.48-8.88,16.82-11,18.53-4.05.83-10.16,1.54-13.73-.69-5.49-3.43-3.69-12.92,6.07-12.52a69.65,69.65,0,0,0,3.33-16.31,151.66,151.66,0,0,0-.47-16.47s-10.88-2.92-17.14-2.75c-15.84.43-24.39,8.32-31,11.54-4.75,2.33-12,2.73-12,2.73a103.87,103.87,0,0,1,.12,25.4c-.69,4.8-1.07,9.63-3.54,11.12-1.86,1.12-13.73,2.74-17.84-1.37s-3.43-10.29-2.06-12.35,5.53-3.94,9.61-3.43c-.74-8.4-2.49-16.94-2.49-16.94s-15.57,1.37-18.32,0c1.37,5.49,3,16.94,3,16.94s1.37,8.24,0,12.35-7.18,5.8-13,5.49c-4-.21-7-.25-9.1-2-3.69-3.21-4.33-9.29-2.57-12.37,2.75-4.8,11-3.43,11-3.43S188,301,186.43,293.86c-1.48-6.7-2.53-11.43-6-16.24s-6.39-15.33-6.39-22.2.69-17.85,7.55-25.39S211.82,220.42,211.82,220.42Z"
    ]
  },
  {
    id: "PATCH_CHEST",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "PATCH_BACK",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "PATCH_BOOTY",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "BRENDALL",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "ARM_RIGHT",
    class: ["cls-3"],
    d: [""]
  },
  {
    id: "ARM_LEFT",
    class: ["cls-3"],
    d: [""]
  },
  {
    id: "PAW_RIGHT",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "PAW_LEFT",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "LEG_LEFT",
    class: ["cls-3"],
    d: [""]
  },
  {
    id: "FOOT_LEFT",
    class: ["cls-1"],
    d: [""]
  },
  {
    id: "EAR_RIGHT_OUT",
    class: ["cls-2"],
    d: [
      "M141.28,108.39s-23-15.25-47.1,21.22C87,140.53,80.07,164,76.43,176.22S69.2,213.61,71.7,221c4.88,14.45,32.63,20.2,41.52,18.77,6.85-1.11,13.38-4.9,14.33-14.32s.43-40.75-.77-47-7.14-25-7.64-29.94S131,131,131,131Z",
      "M139.14,105.47c-20.25-1.77-36.79,12.51-48.07,29.8-8.4,12.89-16.19,41.62-15,56.39s11.3,27.72,22,33.68,35.53,7.26,34.67,3.19S120.58,184,120.33,167.79,131.09,114.1,139.14,105.47Z",
      "M135.94,107.58c-10.36.49-30.94,16.9-41.54,28s-26.87,33.68-25.72,48.46,15.45,34.41,26.11,40.37,35.53,7.26,34.67,3.19-12.08-44.53-12.33-60.75S127.89,116.21,135.94,107.58Z",
      "M145.89,111.46c-19.84-4.36-38,6.47-51.34,20.75-9.93,10.64-21.23,35.86-21.93,49.52s7.74,26.86,17.57,33.75,34.3,11.43,34,7.6-6.43-42.33-4.65-57.19S136.84,118.26,145.89,111.46Z",
      "M138.54,109.27c-19.84-4.36-38,6.47-51.34,20.75C77.27,140.67,67,165.88,66.28,179.54S73,215.41,82.84,222.3s34.3,4.43,34,.59-6.43-44.34-4.65-59.2S129.5,116.07,138.54,109.27Z",
      "M137.15,109.08c-20.25-1.77-36.79,12.51-48.07,29.8-8.4,12.89-16.19,41.62-15,56.39S85.34,223,96,229s35.53,7.26,34.67,3.19-12.08-44.53-12.33-60.75S129.11,117.71,137.15,109.08Z",
      "M140.86,109.32s-23-15.25-47.1,21.22c-7.21,10.92-14.11,34.35-17.75,46.6S68.77,214.54,71.28,222c4.88,14.45,32.63,20.2,41.52,18.77,6.85-1.11,13.38-4.9,14.33-14.32s.43-40.75-.77-47-7.14-25-7.64-29.94,11.85-17.54,11.85-17.54Z"
    ]
  },
  {
    id: "EAR_RIGHT_IN",
    class: ["cls-2"],
    d: [""]
  },
  {
    id: "EAR_LEFT_IN",
    class: ["cls-6"],
    d: [""]
  },
  {
    id: "FACE",
    class: ["cls-3"],
    d: [
      "M115.66,197.46s-5.33-14.41-3-37.18,7.72-38.33,16.7-50.58,18.19-19,36.48-22,45.29-2,61.51,2.47,28.14,15.47,34.23,22.39,17.29,20.77,19.32,38.4a102.84,102.84,0,0,1-5.22,45.21c-4.08,11.67-11.44,24.92-17.76,33.95s-47.38,25.49-84,18.83S115.66,197.46,115.66,197.46Z",
      "M137.82,96.93c-22.24,18.08-24.88,43.5-23.49,71.8,3,60.61,18.55,60.57,31.58,68.11s71.57,11.46,85.4,3.09c7.88-4.77,23.15-7.31,39.13-39.79,3.95-8,15.91-45.61-.37-67.75-3.23-4.39.17-13.25-6.25-24.37-5-8.63-7.24-9.4-30.67-20.25C214.18,79,160.6,78.4,137.82,96.93Z",
      "M118.28,198.44s-5-14.78-2.8-38.15,7.28-39.34,15.7-51.92,17.06-19.5,34.18-22.6,42.4-2.11,57.58,2.46,26.33,15.83,32,22.94,16.16,21.3,18,39.38a115.11,115.11,0,0,1-4.94,46.4c-3.83,12-5.59,25-24.39,36s-41.47,8.24-77.74,6.22S118.28,198.44,118.28,198.44Z",
      "M199.52,66.78c-10.06-8.71-32.53.34-32.07,4.19s8.57,8.71-.52,10.12-28.24,3.73-27.31,10,2.63,13.26-3.07,15.74c-14.93,6.5-25.58,32.69-23.31,60.92,4.88,60.49,20.46,60,33.71,67.08s54.23,16.8,75.56,10.13,32.8-17.78,47.75-50.75c3.69-8.15,14.46-46.09-2.51-67.71-3.37-4.29-.25-13.25-7-24.16-5.25-8.46-18.71-5.75-30.9-28.51C221.71,58.71,212.38,77.93,199.52,66.78Z"
    ]
  },
  {
    id: "MUZZLE",
    class: ["cls-2"],
    d: [
      "M182.88,164.76c22.59,1.44,44,6.86,59.36,21.44,8.7,8.26,15,24.63,14.39,36.58s-1.25,20.08-14.75,21.8-23.6-3.45-27.85-4.23-28.68-6.33-35.16-5.89C171.15,235,144,238.13,139,240s-20.93-.3-25.53-15,.46-32.06,14.58-43.34S163.41,163.52,182.88,164.76Z",
      "M184.27,164.51c20.26,1.34,39.47,6.42,53.24,20.13,7.8,7.76,10.06,17.83,6.85,30.07s-28.77,24.13-69.86,20.15c-31.88-3.09-42.19-10.44-48-22.69s-3-24.88,9.63-35.5S166.8,163.36,184.27,164.51Z",
      "M209.33,168.39c13.18,3.31,28.16,12.47,33.74,34.19,3.69,14.35,5.71,26.48-12.76,30.42-16.13,3.44-14.06,2.1-42.49-.51s-33.57,2.57-49.54-1.85-19.89-24.77-11.5-42.32c6.34-13.27,20.76-19.55,39.92-22.81C176.29,163.88,194,164.54,209.33,168.39Z",
      "M183.78,166.16c21.2,1.35,41.29,6.44,55.7,20.12,8.16,7.75,14.11,23.11,13.5,34.33s-1.18,18.84-13.84,20.46-22.15-3.24-26.13-4a48.4,48.4,0,0,0-15.36.68,120.91,120.91,0,0,1-18.56,3.1c-7.24.49-13.32-.19-18.92-3.42s-12.83-2.43-17.6-.7-19.64-.28-24-14,.44-30.08,13.68-40.67S165.51,165,183.78,166.16Z",
      "M160.19,225.24c-11-4.93-19.59-16.37-19.36-26.21.33-13.79,9.15-34.85,45-32.69,33.78,2,39.88,25.24,37.44,37.52s-15.44,20.77-26.85,23.78C189.38,229.48,168.6,229,160.19,225.24Z",
      "M141.09,175.64c13.15-8.24,25.82-8.28,42.94-7.54s29.24,3.71,37.42,7,18.19,10,22.41,17.14,5.83,18.24,5.66,27.39-3.25,24.48-13.85,29.23-26.22.66-36.34-5c-12-6.66-22-6.85-33.82-3.95-5.09,1.25-17.08,11.05-30.94,5.26s-14.92-33.06-13.47-40.73C123,194.36,127.94,183.88,141.09,175.64Z",
      "M206.65,171.81c12.51,6.61,24.16,17.67,30,30.7s4.95,25.65-11.58,29.77c-14.44,3.6-12.58,2.26-38,.18s-30,3-44.27-1-21.64-23.1-8.11-40.82c9.76-12.78,19-20.5,33.88-22.94C177.2,166.26,194.14,165.2,206.65,171.81Z",
      "M179.06,163.3c46-1.05,65.89,55,52.16,77.71s-40.47,1.08-51.79.95-24.51,12.46-41.6,4.18C115.36,235.26,129.9,164.42,179.06,163.3Z",
      "M158.14,226.34c-9.82-5.27-16.86-15.77-15.74-24a26.76,26.76,0,0,1,5.17-12.55c6-7.9,23-25.07,35.27-24.94,18.58.2,39.78,39.85,36.37,49.95S202.83,230.68,191.86,232C185.1,232.88,165.67,230.38,158.14,226.34Z"
    ]
  },
  {
    id: "NOSE",
    class: ["cls-10"],
    d: [
      "M170.22,176.79c4.55-2.94,14-1.75,16.95-.41s7.42,5,7.68,9.88-2.39,7.46-5.67,9.36a17.67,17.67,0,0,1-12.4,1.45c-4.22-1-10-3.73-11.95-7.34S165.29,180,170.22,176.79Z",
      "M170.77,177c4.55-2.94,14-1.75,16.95-.41s7.42,5,7.68,9.88-2.39,7.46-5.67,9.36a17.67,17.67,0,0,1-12.4,1.45c-4.22-1-10-3.73-11.95-7.34S165.84,180.17,170.77,177Z",
      "M170.45,177.3c4.46-3.09,14-2.2,16.92-1s7.58,4.75,8,9.63-2.15,7.53-5.37,9.54a17.67,17.67,0,0,1-12.35,1.85c-4.25-.9-10.16-3.4-12.18-6.95S165.62,180.65,170.45,177.3Z",
      "M170.7,177.2c4.48-3,13.9-1.94,16.81-.65s7.43,4.85,7.76,9.7-2.26,7.44-5.5,9.38a17.54,17.54,0,0,1-12.28,1.62c-4.2-1-10-3.55-12-7.11S165.85,180.43,170.7,177.2Z",
      "M169.6,175.84c5.11-3.32,15.77-2,19-.5s8.35,5.59,8.65,11.09-2.67,8.39-6.35,10.54-9.19,2.82-13.93,1.66-11.3-4.16-13.45-8.22S164.06,179.43,169.6,175.84Z",
      "M177.4,174.68c6.93-.27,13.87,1.26,15.67,3.31s5.39,9.53,2.05,14.66-9.76,5.65-15.41,5.65-11.56-1.54-14.64-4.88-.61-11.8,1.8-14.12C168.57,177.67,170.73,174.93,177.4,174.68Z",
      "M170.28,176.7c4.44-2.78,13.66-2.26,17.69-.42,3,1.39,7.74,5.21,8,10.32a10.23,10.23,0,0,1-5.92,9.77,23.48,23.48,0,0,1-12.94,1.51c-4.83-.82-10.91-3.61-12.47-7.66S165.08,180,170.28,176.7Z",
      "M169.77,174.15c-7.48,3.22-13.08,16.66-4,25.52,6.37,6.24,22.35,4.22,28.93,1.06,7-3.35,7.26-15.68,2.49-22.4C192.6,171.93,177.89,170.65,169.77,174.15Z",
      "M169.68,175.49c4.48-3,13.9-1.94,16.81-.65s7.43,4.85,7.76,9.7-2.26,7.44-5.5,9.38a17.54,17.54,0,0,1-12.28,1.62c-4.2-1-10-3.55-12-7.11S164.83,178.73,169.68,175.49Z",
      "M194.89,183.34c.93,4.84-1,12.8-7.39,13.3s-13.79,1.48-17.73-1-6.4-6.89-3.45-13.79,10.83-6.4,15.27-5.91S193.62,176.76,194.89,183.34Z"
    ]
  },
  {
    id: "EYE_PATCH_RIGHT",
    class: ["cls-4"],
    d: [""]
  },
  {
    id: "EYE_PATCH_LEFT",
    class: ["cls-4"],
    d: [""]
  },
  {
    id: "EYE_RIGHT",
    class: ["cls-9"],
    d: [
      "M147.51,155.85c-.29,5.78-2,8.36-5.18,8.87s-5.5-3.46-5.63-8.16c-.07-2.8.24-8.24,5.32-8.63C146.18,147.62,147.7,152,147.51,155.85Z",
      "M154.89,154.07c-.16,5.78-1.81,8.4-5,9s-5.58-3.33-5.81-8c-.14-2.79.06-8.25,5.13-8.74C153.37,145.88,155,150.26,154.89,154.07Z",
      "M159.32,153.77c-.28,5.78-2,8.36-5.17,8.88s-5.51-3.45-5.64-8.15c-.08-2.8.23-8.24,5.31-8.63C158,145.54,159.5,150,159.32,153.77Z",
      "M150.59,153.54c0,5.78-1.64,8.44-4.8,9.08s-5.64-3.22-6-7.92c-.19-2.79-.11-8.25,5-8.84C148.91,145.37,150.62,149.72,150.59,153.54Z",
      "M148,153.89c-.08,5.78-1.68,8.43-4.85,9.06s-5.63-3.25-5.93-7.95c-.18-2.79-.07-8.25,5-8.82C146.34,145.72,148,150.08,148,153.89Z",
      "M150.85,144.45c1.83-.21,4,1.81,4.7,3.68s.76,7.55.4,9.1-2.27,5-4.7,5.24-4.5-2.47-5.14-4.1-1.32-6.47-.54-8.45S148.54,144.71,150.85,144.45Z"
    ]
  },
  {
    id: "EYE_LEFT",
    class: ["cls-8"],
    d: [
      "M236.78,150c2.78.4,5.91,3.92,4.42,10s-5.4,7.78-7.63,7-4.27-5-3.68-9S233,149.46,236.78,150Z",
      "M226.72,150.17c2.79.33,6,3.79,4.64,9.94s-5.22,7.9-7.47,7.15-4.38-4.87-3.88-8.87S222.93,149.71,226.72,150.17Z",
      "M219,149c2.78.39,5.92,3.91,4.43,10s-5.39,7.79-7.62,7-4.27-5-3.69-9S215.22,148.51,219,149Z",
      "M236.29,149.15c2.8.28,6.07,3.67,4.84,9.84s-5.07,8-7.33,7.29-4.47-4.79-4.05-8.8S232.49,148.77,236.29,149.15Z",
      "M233.71,149.9c2.8.29,6.05,3.7,4.79,9.87s-5.1,8-7.37,7.26-4.45-4.81-4-8.82S229.91,149.51,233.71,149.9Z",
      "M228.13,148.58c2.07.18,4.53,2.73,4.84,4.3s.28,6.1-1,8.48-4.31,4.72-6.53,4.33-4-4.22-4.06-5.81.57-6.14,1.49-7.65S226.29,148.42,228.13,148.58Z"
    ]
  },
  {
    id: "BROW_RIGHT",
    class: ["cls-4"],
    d: [""]
  },
  {
    id: "BROW_LEFT",
    class: ["cls-4"],
    d: [""]
  },
  {
    id: "EAR_LEFT_OUT",
    class: ["cls-2"],
    d: [
      "M245.25,108.45c4.88-4.14,24.71-5.88,36.23,1.5s26.42,45.22,30.86,61.64,6.5,46-1.56,56.48-10.5,10.83-30.62,10.12-21.72-25.53-20.66-43.71c.91-15.77.41-53.78-4.19-57.23C246.16,130.38,240.38,112.59,245.25,108.45Z",
      "M242.1,100.71c35,5.74,51.51,29.8,60.18,62.47,4.81,18.11,7.49,32.67-4.13,44.79s-28.28,18.39-34.61,18.58-.17-39.24-1.15-54.53S267.29,127.51,242.1,100.71Z",
      "M247.9,106.83c14.23,6.64,53.14,42.1,60.18,59.46S315.56,199,304,211.08s-28.28,18.39-34.61,18.58-.17-39.24-1.15-54.53S254.1,111.94,247.9,106.83Z",
      "M239.5,108.46c40.24,7.46,67.29,31.45,75.47,62.07,4.53,17,6.81,30.55-7.37,41s-33.86,15.18-41.22,15,2.07-36.15,1.81-50.29S267.19,134.69,239.5,108.46Z",
      "M242.64,108.1c34.84-6.65,69,27.55,78.89,57.65,5.5,16.69,10.17,41.47-3.38,52.71s-31.93,9.44-43.17,6.33-1.5-39.5-2.83-52.67c-1.64-16.17-11.14-32.21-23.33-47.67C239.62,112.79,227.65,111,242.64,108.1Z",
      "M250.29,110.92c36.82,5.69,50,23.15,63.49,54.36,6.5,15,7.62,40.67-4.63,52.81S277.33,234.85,271,231.36c-5.76-3.18-.54-31.57.52-49.15C272.46,166.92,276.78,137.68,250.29,110.92Z",
      "M248.83,107.37c4.88-4.14,24.71-5.88,36.23,1.5s26.42,45.22,30.86,61.64,6.5,46-1.56,56.48-10.5,10.83-30.62,10.12S262,211.57,263.08,193.39c.91-15.77.41-53.78-4.19-57.23C249.74,129.3,244,111.51,248.83,107.37Z"
    ]
  }
]
