import React, { Component } from "react"
import { getPetsByUserId } from "./../utils/rubyAPI"
import AnimatedWrapper from "./../utils/AnimatedWrapper"
import LoadingScreen from "./LoadingScreen"
import ListOfYourPets from "./ListOfYourPets"

class Main extends Component {
  state = {
    pets: false,
    loading: true
  }
  componentDidMount() {
    this.props.updateHeaderType("home")
    this.props.setNewPetName(false)
    window.scrollTo(0, 0)
    getPetsByUserId(this.props.userId).then(pets => {
      this.setState({ pets, loading: false })
    })
  }
  render() {
    const { pets, loading } = this.state
    if (loading) return <LoadingScreen />
    return <ListOfYourPets pets={pets} />
  }
}

// export default Main
export default AnimatedWrapper(Main)
