import React from "react"

export default function ImageCopiedModal() {
  return (
    <div id="imageCopiedModal" className="modal modal-fixed-footer rf_modal">
      <div className="modal-content">
        <div className="center">
          <img
            src="http://haroldl.com/images/Copy-Anim.png"
            className="rf_modal-anim center"
            alt="copied"
          />
        </div>
        <h4>Image copied</h4>
        <p>Your dog’s image has been copied to the clipboard.</p>
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close waves-effect waves-green btn-flat">
          OK
        </a>
      </div>
    </div>
  )
}
